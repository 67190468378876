import {
  Dialog,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import * as Yup from "yup";
import create from "zustand";
import * as API from "../../CommonServices/Services";
import { STATES_DISTRICTS } from "../../CommonServices/StatesAndDistricts";
import * as Utils from "../../CommonServices/Utils";
import { status_dialog } from "./StatusDialog";
const addSubBrokerStore = create((set) => ({
  data: undefined,
  stateList: undefined,
  onSubmit: undefined,
  close: () => {
    set({
      onSubmit: undefined,
    });
  },
}));

const SubBrokerDialog = () => {
  const { data, stateList, onSubmit, close } = addSubBrokerStore();
  const [loginFailedErrorMessage, setLoginFailedErrorMessage] = useState(null);
  const [district, setDistrict] = useState(null);
  const [edit, setEdit] = useState(false);
  let userType = localStorage.getItem("userType");
  const [fileName, setFilename] = useState("");
  let userName = localStorage.getItem("userName");
  const [uploadedFile, setUploadedFile] = useState(null);
  let formData = new FormData();
  const [uploadType, setUploadType] = useState("");
  function handleAddressproof(e) {
    let file = e.target.files[0];
    setUploadType(file.type);
    if (file.type === "image/jpeg") {
      setUploadedFile(file);
      formData.append("signature", file);
      setFilename(file.name);
      addSubBrokerForm.setFieldValue("signature", file.name);
    }
  }
  const addSubBrokerForm = useFormik({
    initialValues: {
      panNo: Utils.isNullOrUndefined(data) ? "" : data?.panNo,
      designation: Utils.isNullOrUndefined(data) ? "" : data?.designation,
      name: Utils.isNullOrUndefined(data) ? "" : data?.name,
      userid: userName,
      emailId: Utils.isNullOrUndefined(data) ? "" : data?.emailId,
      phoneNo: Utils.isNullOrUndefined(data) ? "" : data?.phoneNo,
      state: Utils.isNullOrUndefined(data) ? "" : data?.state,
      subDistrict: Utils.isNullOrUndefined(data) ? "" : data?.subDistrict,
      isEdit: Utils.isNullOrUndefined(data) ? false : true,
      parentReferralCode: Utils.isNullOrUndefined(data) ? '' : data?.parentReferralCode,
      signature: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      panNo: Yup.string().required("PAN is required!"),
      designation: Yup.string().required("Designation is required!"),
      name: Yup.string().required("Name is required!"),
      emailId: Yup.string().required("Email is required!"),
      phoneNo: Yup.string()
        .required("Phone Number is required!")
        .min(10, "Phone number must be of 10 digits!")
        .max(10, "Phone number must be of 10 digits!"),
      state: Yup.string().required("State is required!"),
      subDistrict: Yup.string().required("District is required!"),
      signature: Yup.string().required("Document is required!"),
    }),
    onSubmit: (values) => {
      formData.append("UserId", values?.userid);
      formData.append("State", values?.state);
      formData.append("SubDistrict", values?.subDistrict);
      formData.append("PanNo", values?.panNo);
      formData.append("EmailId", values?.emailId);
      formData.append("Name", values?.name);
      formData.append("PhoneNo", values?.phoneNo);
      formData.append("Designation", values?.designation);
      formData.append("signature", uploadedFile);
      formData.append("ParentReferralCode", values?.parentReferralCode);

      if (Utils.isNullOrUndefined(data)) {
        API.PostFormMethod("/admin/createReferralCode", formData)
          .then((res) => {
            let status_details = {
              ...Utils.STATUS_POPUP_DETAILS.success,
              message: "Sub Broker has been created!",
              title: "Success",
            };
            addSubBrokerForm.resetForm();
            status_dialog(status_details, res?.data?.referralCode, () => {
              onSubmit();
              setLoginFailedErrorMessage(null);
              close();
            });
            setUploadType("");
            setUploadedFile(null);
          })
          .catch(function (error) {
            setLoginFailedErrorMessage(error?.response?.data?.message);
          });
      } else {
        API.PostFormMethod("/admin/updateSubBrokerDetails", formData)
          .then((res) => {
            let status_details = {
              ...Utils.STATUS_POPUP_DETAILS.success,
              message: "Sub Broker has been updated!",
              title: "Success",
            };
            addSubBrokerForm.resetForm();
            status_dialog(status_details, res?.data?.referralCode, () => {
              onSubmit();
              setLoginFailedErrorMessage(null);
              close();
            });
            setUploadType("");
            setUploadedFile(null);
          })
          .catch(function (error) {
            setLoginFailedErrorMessage(error?.response?.data?.message);
          });
      }
    },
  });

  function nameChange(event) {
    addSubBrokerForm.setFieldValue("name", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function emailChange(event) {
    addSubBrokerForm.setFieldValue("emailId", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function phoneChange(event) {
    addSubBrokerForm.setFieldValue("phoneNo", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function passwordChange(event) {
    addSubBrokerForm.setFieldValue("panNo", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function designationChange(event) {
    addSubBrokerForm.setFieldValue("designation", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function districtChange(event) {
    addSubBrokerForm.setFieldValue("subDistrict", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function parentRefChange(event) {
    addSubBrokerForm.setFieldValue("parentReferralCode", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function stateChange(event) {
    addSubBrokerForm.setFieldValue("state", event.target.value);
    let district_list = STATES_DISTRICTS.filter(
      (obj) => obj.state === event.target.value
    );
    if (Utils.isNullOrUndefined(stateList)) {
      setDistrict(district_list[0]);
    } else {
      setEdit(true)
      setDistrict(district_list[0]);

    }
    setLoginFailedErrorMessage(null);
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "60%",
          height: "80%",
          maxWidth: "100%",
        },
      }}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
          addSubBrokerForm.resetForm();
          setLoginFailedErrorMessage(null);
        }
      }}
    >
      <DialogTitle className="d-flex flex-row justify-content-between align-items-center">
        <div>Add New Sub Broker</div>
        <IconButton
          onClick={() => {
            close();
            addSubBrokerForm.resetForm();
            setLoginFailedErrorMessage(null);
          }}
        >
          <MdOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik onSubmit={addSubBrokerForm.onSubmit}>
          <Form>
            <div className="d-flex flex-column pkt-margin-top-10">
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Name :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="name"
                    placeholder="Enter Name"
                    value={addSubBrokerForm.values.name}
                    onChange={nameChange}
                    error={
                      addSubBrokerForm.touched.name &&
                        addSubBrokerForm.errors.name
                        ? true
                        : false
                    }
                    onBlur={addSubBrokerForm.handleBlur}
                    disabled={Utils.isNullOrUndefined(data) ? false : true}
                  />
                  <div>
                    {addSubBrokerForm.touched.name &&
                      addSubBrokerForm.errors.name ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.name}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>{" "}
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Email :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="emailId"
                    placeholder="Enter email"
                    value={addSubBrokerForm.values.emailId}
                    onChange={emailChange}
                    error={
                      addSubBrokerForm.touched.emailId &&
                        addSubBrokerForm.errors.emailId
                        ? true
                        : false
                    }
                    onBlur={addSubBrokerForm.handleBlur}
                  />
                  <div>
                    {addSubBrokerForm.touched.emailId &&
                      addSubBrokerForm.errors.emailId ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.emailId}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Phone No. :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="phoneNo"
                    placeholder="Enter phone number"
                    value={addSubBrokerForm.values.phoneNo}
                    onChange={phoneChange}
                    error={
                      addSubBrokerForm.touched.phoneNo &&
                        addSubBrokerForm.errors.phoneNo
                        ? true
                        : false
                    }
                    onBlur={addSubBrokerForm.handleBlur}
                  />
                  <div>
                    {addSubBrokerForm.touched.phoneNo &&
                      addSubBrokerForm.errors.phoneNo ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.phoneNo}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Pan No. :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="panNo"
                    placeholder="Enter Pan No"
                    value={addSubBrokerForm.values.panNo}
                    onChange={passwordChange}
                    error={
                      addSubBrokerForm.touched.panNo &&
                        addSubBrokerForm.errors.panNo
                        ? true
                        : false
                    }
                    onBlur={addSubBrokerForm.handleBlur}
                  />
                  <div>
                    {addSubBrokerForm.touched.panNo &&
                      addSubBrokerForm.errors.panNo ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.panNo}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Designation :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="designation"
                    placeholder="Enter designation"
                    value={addSubBrokerForm.values.designation}
                    onChange={designationChange}
                    error={
                      addSubBrokerForm.touched.designation &&
                        addSubBrokerForm.errors.designation
                        ? true
                        : false
                    }
                    onBlur={addSubBrokerForm.handleBlur}
                  />
                  <div>
                    {addSubBrokerForm.touched.designation &&
                      addSubBrokerForm.errors.designation ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.designation}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  State :
                </div>
                <div className="d-flex flex-column col-10">
                  <FormControl size="small">
                    <InputLabel id="demo-select-small">State</InputLabel>
                    <Select
                      className="pkt-height-40"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addSubBrokerForm.values.state}
                      label="State"
                      onChange={stateChange}
                      name="state"
                      error={
                        addSubBrokerForm.touched.state &&
                          addSubBrokerForm.errors.state
                          ? true
                          : false
                      }
                      onBlur={addSubBrokerForm.handleBlur}
                    >
                      {Utils.isNullOrUndefined(STATES_DISTRICTS) ? (
                        <MenuItem>No states found!</MenuItem>
                      ) : (
                        STATES_DISTRICTS.map((item, index) => {
                          return (
                            <MenuItem
                              key={item?.state}
                              aria-controls={item?.state}
                              value={item?.state}
                            >
                              {item?.state}
                            </MenuItem>
                          );
                        })
                      )}
                    </Select>
                  </FormControl>

                  <div>
                    {addSubBrokerForm.touched.state &&
                      addSubBrokerForm.errors.state ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.state}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  District :
                </div>
                <div className="d-flex flex-column col-10">
                  <FormControl size="small">
                    <InputLabel id="demo-select-small">District</InputLabel>
                    <Select
                      className="pkt-height-40"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addSubBrokerForm.values.subDistrict}
                      label="District"
                      onChange={districtChange}
                      name="subDistrict"
                      error={
                        addSubBrokerForm.touched.subDistrict &&
                          addSubBrokerForm.errors.subDistrict
                          ? true
                          : false
                      }
                      onBlur={addSubBrokerForm.handleBlur}
                      disabled={
                        addSubBrokerForm.values.state === "" ? true : false
                      }
                    >
                      {edit === false && (Utils.isNullOrUndefined(district) && Utils.isNullOrUndefined(stateList)) ? (
                        <MenuItem>No states found!</MenuItem>
                      ) :
                        edit === false && Utils.isNullOrUndefined(stateList) ?
                          (
                            district?.districts.map((item, index) => {
                              return (
                                <MenuItem
                                  key={item}
                                  aria-controls={item}
                                  value={item}
                                >
                                  {item}
                                </MenuItem>
                              );
                            })
                          ) :
                          edit === false && !Utils.isNullOrUndefined(stateList) ?
                            (
                              stateList?.districts.map((item, index) => {
                                return (
                                  <MenuItem
                                    key={item}
                                    aria-controls={item}
                                    value={item}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })
                            )
                            : edit === true ?
                              (
                                district?.districts.map((item, index) => {
                                  return (
                                    <MenuItem
                                      key={item}
                                      aria-controls={item}
                                      value={item}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })
                              )


                              : null}

                    </Select>
                  </FormControl>

                  <div>
                    {addSubBrokerForm.touched.subDistrict &&
                      addSubBrokerForm.errors.subDistrict ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.subDistrict}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Parent Ref Code :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="parentReferralCode"
                    placeholder="Enter Parent Ref Code"
                    value={addSubBrokerForm.values.parentReferralCode}
                    onChange={parentRefChange}
                    error={
                      addSubBrokerForm.touched.parentReferralCode &&
                        addSubBrokerForm.errors.parentReferralCode
                        ? true
                        : false
                    }
                    onBlur={addSubBrokerForm.handleBlur}
                  />
                  <div>
                    {addSubBrokerForm.touched.parentReferralCode &&
                      addSubBrokerForm.errors.parentReferralCode ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.parentReferralCode}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Signature :
                </div>

                <div className="d-flex flex-column col-10">
                  <div className="d-flex ">
                    <Button
                      className="pkt-font-size-14"
                      variant="outlined"
                      style={{
                        border: "1px solid indigo",
                        color: "indigo",
                      }}
                      component="label"
                      onChange={(e) => {
                        handleAddressproof(e);
                      }}
                    >
                      Upload File
                      <input
                        type="file"
                        hidden
                        error={addSubBrokerForm.errors.signature ? true : false}
                      />
                    </Button>
                    <span className="pkt-margin-left-15 pkt-font-bold">
                      {uploadType === "image/jpeg" ? (
                        addSubBrokerForm.values.signature
                      ) : uploadType === "" ? (
                        ""
                      ) : (
                        <span className="pkt-error">
                          Please upload in .JPG format.
                        </span>
                      )}
                    </span>
                  </div>{" "}
                  <div>
                    {addSubBrokerForm.touched.signature &&
                      addSubBrokerForm.errors.signature ? (
                      <span className="pkt-error">
                        {addSubBrokerForm.errors.signature}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        {!Utils.isNullOrUndefined(loginFailedErrorMessage) ? (
          <div
            className="pkt-error pkt-width-percentage-100"
            style={{ fontWeight: 500 }}
          >
            {loginFailedErrorMessage}
          </div>
        ) : (
          <div></div>
        )}
        <Button
          variant="contained"
          disabled={!Utils.isNullOrUndefined(loginFailedErrorMessage)}
          onClick={() => {
            addSubBrokerForm.handleSubmit();
          }}
          type="submit"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { SubBrokerDialog };

export const addSubBroker_dialog = (data, stateList, onSubmit) => {
  addSubBrokerStore.setState({ data, stateList, onSubmit });
};

import {
  Autocomplete,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { MdOutlineClose } from "react-icons/md";
import create from "zustand";
import * as Utils from "../../CommonServices/Utils";
const rejectStore = create((set) => ({
  data: undefined,
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));

const RejectDialog = () => {
  const { data, onSubmit, close } = rejectStore();
  const handleChange = (key, value) => {
    Utils.reject_data[key].rejectionReason = value;
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "50%",
          height: "40%",
          maxWidth: "100%",
        },
      }}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle className="d-flex flex-row justify-content-between align-items-center">
        {data}{" "}
        <IconButton onClick={close}>
          <MdOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {Utils.allFormUncheched(Utils.reject_data) ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%" }}
          >
            {" "}
            Please select any document to be rejected.
          </div>
        ) : null}
        {Utils.reject_data.map((item, key) => {
          return item.isRejected === true ? (
            <React.Fragment key={key}>
              <div
                className="d-flex flex-row align-items-center"
                style={{
                  marginTop: "15px",
                  fontWeight: 500,
                  fontSize: "18px",
                }}
              >
                <div className="col-2">{item?.docType} :</div>
                <div className="d-flex flex-column col-10">
                  <div aria-controls={key}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={Utils.RejectedReasons.map((option) => option)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Reason"
                          onChange={(event, value) => {
                            handleChange(key, value);
                          }}
                        />
                      )}
                      onChange={(event, value) => {
                        handleChange(key, value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : null;
        })}
      </DialogContent>
      <DialogActions>
        <Tooltip
          title={
            Utils.allFormUncheched(Utils.reject_data)
              ? "Please select any document"
              : "Reject"
          }
        >
          <span>
            <Button
              disabled={Utils.allFormUncheched(Utils.reject_data)}
              variant="contained"
              onClick={() => {
                onSubmit();
                close();
                Utils.status = "REJCTED";
              }}
            >
              Ok
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export { RejectDialog };

export const reject_dialog = (data, onSubmit) => {
  rejectStore.setState({ data, onSubmit });
};

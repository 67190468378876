import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as API from "../../CommonServices/Services";
import { STATES_DISTRICTS } from "../../CommonServices/StatesAndDistricts";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
import * as Utils from "../../CommonServices/Utils";
export default function Pockets() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  let userName = localStorage.getItem("userName");
  let userType = localStorage.getItem("userType");
  let navigate = useNavigate();

  const viewPocket = (id) => {
    navigate("/pocket/" + id);
  };
  const fetchData = async () => {
    setLoading(true);
    API.GetMethod("/rbac/fetchAllPockets")
      .then((res) => {
        setData(res?.data?.fetchAllPocketsDetailsResponse);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData(null);
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="d-flex flex-column pkt-height-percentage-100 pkt-height-percentage-100 pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height pkt-padding-20">
        <div className="d-flex flex-row justify-content-between align-items-centers">
          <div style={{ fontWeight: 600, fontSize: "20px" }}>Pockets</div>
          <Button
            variant="contained"
            component={Link}
            to="/pocket"
            disabled={userType === "AGENT" ? true : false}
          >
            Add pocket
          </Button>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center pkt-width-percentage-100 pkt-height-percentage-100">
          {loading ? (
            <div className="pkt-height-percentage-100 pkt-width-percentage-100">
              <Loader />
            </div>
          ) : !Utils.isNullOrUndefined(data) ? (
            <div
              className="d-flex flex-wrap pkt-padding-20 pkt-height-percentage-100 pkt-margin-top-10 pkt-width-percentage-100"
              style={{ overflow: "auto" }}
            >
              {data.map((item) => {
                return (
                  <div
                    className="pkt-padding-10 col-12 col-sm-12 col-md-6 col-lg-3 col-xl-2"
                    key={item?.pocketId}
                  >
                    <Card sx={{ maxHeight: 300 }}>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ objectFit: "fill" }}
                        image={item?.pocketImage}
                        alt="Pocket Image"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {item?.pocketName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {item?.pocketShortDesc}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => {
                            viewPocket(item?.pocketId);
                          }}
                        >
                          View/Edit
                        </Button>
                      </CardActions>
                    </Card>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>No Pockets found!</div>
          )}
        </div>
      </div>
    </div>
  );
}

import {
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import { Form, Formik, useFormik } from "formik";
import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import * as Yup from "yup";
import create from "zustand";
import * as API from "../../CommonServices/Services";
import * as Utils from "../../CommonServices/Utils";
import { status_dialog } from "./StatusDialog";

const addProfileStore = create((set) => ({
  data: undefined,
  roleIds: undefined,
  onSubmit: undefined,
  close: () => {
    set({
      onSubmit: undefined,
    });
  },
}));

const AddProfileDialog = () => {
  const [showEye, updateEye] = useState(true);
  const { data, roleIds, onSubmit, close } = addProfileStore();
  const [loginFailedErrorMessage, setLoginFailedErrorMessage] = useState(null);
  const [selectedRole, setSelected] = useState(2);
  let userType = localStorage.getItem("userType");
  let userName = localStorage.getItem("userName");

  const toggleEye = () => {
    updateEye(!showEye);
  };
  const addProfileForm = useFormik({
    initialValues: {
      type: Utils.isNullOrUndefined(data) ? "" : data?.userType,
      adminPassword: "",
      adminUserName: Utils.isNullOrUndefined(data)
        ? ""
        : data?.userName.slice(0, data?.userName.length - 2),
      createdBy: userName,
      emailId: Utils.isNullOrUndefined(data) ? "" : data?.emailId,
      phoneNumber: Utils.isNullOrUndefined(data) ? "" : data?.phoneNumber,
      roleIDs: Utils.isNullOrUndefined(data) ? "" : roleIds,
      statusCode: Utils.isNullOrUndefined(data) ? 1 : data?.statusCode,
      isEdit: Utils.isNullOrUndefined(data) ? false : true,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      type: Yup.string().required("Type ID is required!"),
      adminPassword: Yup.string().when("isEdit", {
        is: false,
        then: Yup.string().required("Must enter email address"),
        otherwise: Yup.string(),
      }),
      adminUserName: Yup.string().required("Name is required!"),
      emailId: Yup.string().required("Email is required!"),
      phoneNumber: Yup.string()
        .required("Phone Number is required!")
        .min(10, "Phone number must be of 10 digits!")
        .max(10, "Phone number must be of 10 digits!"),
    }),
    onSubmit: (values) => {
      values.createdBy = userName;
      values.roleIDs = [values.roleIDs];
      if (Utils.isNullOrUndefined(data)) {
        if (values.type === 2) {
          API.PostMethod("/rbac/createAdmin", values)
            .then((res) => {
              let status_details = {
                ...Utils.STATUS_POPUP_DETAILS.success,
                message: "Admin has been created!",
                title: "Success",
              };
              status_dialog(status_details, null, () => {
                onSubmit();
                addProfileForm.resetForm();
                setLoginFailedErrorMessage(null);
                setSelected("Send Notification");
                close();
              });
            })
            .catch(function (error) {
              setLoginFailedErrorMessage(error?.response?.data?.message);
            });
        } else {
          API.PostMethod("/rbac/createAgent", values)
            .then((res) => {
              let status_details = {
                ...Utils.STATUS_POPUP_DETAILS.success,
                title: "Success",
                message: "Agent has been created!",
              };
              status_dialog(status_details, null, () => {
                onSubmit();
                addProfileForm.resetForm();
                setLoginFailedErrorMessage(null);
                setSelected("Send Notification");
                close();
              });
            })
            .catch(function (error) {
              setLoginFailedErrorMessage(error?.response?.data?.message);
            });
        }
      } else {
        let payload = values;
        payload.adminPassword = "";
        if (values.type === 2) {
          payload.adminUserName = payload.adminUserName + "_2";
          API.PostMethod("/rbac/modifyAdmin", payload)
            .then((res) => {
              let status_details = {
                ...Utils.STATUS_POPUP_DETAILS.success,
                message: "Admin has been updated!",
                title: "Success",
              };
              status_dialog(status_details, null, () => {
                onSubmit();
                addProfileForm.resetForm();
                setLoginFailedErrorMessage(null);
                setSelected("Send Notification");
                close();
              });
            })
            .catch(function (error) {
              setLoginFailedErrorMessage(error?.response?.data?.message);
            });
        } else {
          payload.adminUserName = payload.adminUserName + "_3";

          API.PostMethod("/rbac/modifyAgent", values)
            .then((res) => {
              let status_details = {
                ...Utils.STATUS_POPUP_DETAILS.success,
                title: "Success",
                message: "Agent has been updated!",
              };
              status_dialog(status_details, null, () => {
                onSubmit();
                addProfileForm.resetForm();
                setLoginFailedErrorMessage(null);
                setSelected("Send Notification");
                close();
              });
            })
            .catch(function (error) {
              setLoginFailedErrorMessage(error?.response?.data?.message);
            });
        }
      }
    },
  });
  function typeChange(event) {
    addProfileForm.setFieldValue("type", event.target.value);
    setLoginFailedErrorMessage(null);
  }

  function nameChange(event) {
    addProfileForm.setFieldValue("adminUserName", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function emailChange(event) {
    addProfileForm.setFieldValue("emailId", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function phoneChange(event) {
    addProfileForm.setFieldValue("phoneNumber", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function passwordChange(event) {
    addProfileForm.setFieldValue("adminPassword", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function statusChange(event) {
    let selectedValue = parseInt(event.target.value);
    addProfileForm.setFieldValue("statusCode", selectedValue);
    setLoginFailedErrorMessage(null);
  }
  function roleChange(event) {
    addProfileForm.setFieldValue("roleIDs", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "60%",
          height: "60%",
          maxWidth: "100%",
        },
      }}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
          addProfileForm.resetForm();
          setSelected("Send Notification");
          setLoginFailedErrorMessage(null);
        }
      }}
    >
      <DialogTitle className="d-flex flex-row justify-content-between align-items-center">
        <div>Add New Profile</div>
        <IconButton
          onClick={() => {
            close();
            addProfileForm.resetForm();
            setSelected("Send Notification");
            setLoginFailedErrorMessage(null);
          }}
        >
          <MdOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik onSubmit={addProfileForm.onSubmit}>
          <Form>
            <div className="d-flex flex-column pkt-margin-top-10">
              <div className="d-flex flex-row">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Type :
                </div>
                <div className="d-flex flex-column col-10">
                  <FormControl size="small">
                    <InputLabel id="demo-select-small">Type</InputLabel>
                    <Select
                      className="pkt-height-40"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addProfileForm.values.type}
                      label="Type"
                      onChange={typeChange}
                      name="type"
                      error={
                        addProfileForm.touched.type &&
                        addProfileForm.errors.type
                          ? true
                          : false
                      }
                      onBlur={addProfileForm.handleBlur}
                    >
                      <MenuItem
                        value={2}
                        disabled={localStorage?.userType === "ADMIN"}
                      >
                        Admin
                      </MenuItem>
                      <MenuItem value={3}>Agent</MenuItem>
                    </Select>
                  </FormControl>

                  <div>
                    {addProfileForm.touched.type &&
                    addProfileForm.errors.type ? (
                      <span className="pkt-error">
                        {addProfileForm.errors.type}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>{" "}
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Name :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="adminUserName"
                    placeholder="Enter Name"
                    value={addProfileForm.values.adminUserName}
                    onChange={nameChange}
                    error={
                      addProfileForm.touched.adminUserName &&
                      addProfileForm.errors.adminUserName
                        ? true
                        : false
                    }
                    onBlur={addProfileForm.handleBlur}
                    disabled={Utils.isNullOrUndefined(data) ? false : true}
                  />
                  <div>
                    {addProfileForm.touched.adminUserName &&
                    addProfileForm.errors.adminUserName ? (
                      <span className="pkt-error">
                        {addProfileForm.errors.adminUserName}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>{" "}
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Email :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="emailId"
                    placeholder="Enter email"
                    value={addProfileForm.values.emailId}
                    onChange={emailChange}
                    error={
                      addProfileForm.touched.emailId &&
                      addProfileForm.errors.emailId
                        ? true
                        : false
                    }
                    onBlur={addProfileForm.handleBlur}
                  />
                  <div>
                    {addProfileForm.touched.emailId &&
                    addProfileForm.errors.emailId ? (
                      <span className="pkt-error">
                        {addProfileForm.errors.emailId}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Phone No. :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    id="outlined-size-small"
                    size="small"
                    name="phoneNumber"
                    placeholder="Enter phone number"
                    value={addProfileForm.values.phoneNumber}
                    onChange={phoneChange}
                    error={
                      addProfileForm.touched.phoneNumber &&
                      addProfileForm.errors.phoneNumber
                        ? true
                        : false
                    }
                    onBlur={addProfileForm.handleBlur}
                  />
                  <div>
                    {addProfileForm.touched.phoneNumber &&
                    addProfileForm.errors.phoneNumber ? (
                      <span className="pkt-error">
                        {addProfileForm.errors.phoneNumber}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Password :
                </div>
                <div className="d-flex flex-column col-10">
                  <TextField
                    className="w-100"
                    type={showEye ? "password" : "text"}
                    id="outlined-size-small"
                    size="small"
                    name="adminPassword"
                    placeholder="Enter password"
                    value={addProfileForm.values.adminPassword}
                    onChange={passwordChange}
                    error={
                      addProfileForm.touched.adminPassword &&
                      addProfileForm.errors.adminPassword
                        ? true
                        : false
                    }
                    onBlur={addProfileForm.handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {showEye ? (
                            <AiFillEye onClick={toggleEye} />
                          ) : (
                            <AiFillEyeInvisible onClick={toggleEye} />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div>
                    {addProfileForm.touched.adminPassword &&
                    addProfileForm.errors.adminPassword ? (
                      <span className="pkt-error">
                        {addProfileForm.errors.adminPassword}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Role :
                </div>
                <div className="d-flex flex-column col-10">
                  <FormControl size="small">
                    <InputLabel id="demo-select-small">Role</InputLabel>
                    <Select
                      className="pkt-height-40"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addProfileForm.values.roleIDs}
                      label="Role"
                      onChange={roleChange}
                      name="roleIds"
                      error={
                        addProfileForm.touched.roleIDs &&
                        addProfileForm.errors.roleIDs
                          ? true
                          : false
                      }
                      onBlur={addProfileForm.handleBlur}
                    >
                      <MenuItem
                        value={2}
                        disabled={localStorage?.userType === "ADMIN"}
                      >
                        Admin
                      </MenuItem>{" "}
                      <MenuItem value={3}>Agent</MenuItem>{" "}
                      <MenuItem value={4}>KYC</MenuItem>{" "}
                      <MenuItem value={5}>Send Notification</MenuItem>
                    </Select>
                  </FormControl>

                  <div>
                    {addProfileForm.touched.roleIDs &&
                    addProfileForm.errors.roleIDs ? (
                      <span className="pkt-error">
                        {addProfileForm.errors.roleIDs}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row pkt-margin-top-20 align-items-center">
                <div style={{ fontWeight: 500 }} className="col-2">
                  Status :
                </div>
                <div className="d-flex flex-column col-10">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={statusChange}
                      value={addProfileForm.values.statusCode}
                    >
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="ACTIVE"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio />}
                        label="INACTIVE"
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio />}
                        label="SUSPENDED"
                      />
                      <FormControlLabel
                        value={4}
                        control={<Radio />}
                        label="TERMINATED"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </DialogContent>
      <DialogActions>
        {!Utils.isNullOrUndefined(loginFailedErrorMessage) ? (
          <div
            className="pkt-error pkt-width-percentage-100"
            style={{ fontWeight: 500 }}
          >
            {loginFailedErrorMessage}
          </div>
        ) : (
          <div></div>
        )}
        <Button
          variant="contained"
          disabled={!Utils.isNullOrUndefined(loginFailedErrorMessage)}
          onClick={() => {
            addProfileForm.handleSubmit();
          }}
          type="submit"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { AddProfileDialog };

export const addProfile_dialog = (data, roleIds, onSubmit) => {
  addProfileStore.setState({ data, roleIds, onSubmit });
};

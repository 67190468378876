import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;

//Get Method
export let GetMethod = async (url) => {
  let localStorageKey = localStorage.getItem("pkt-auth-key");
  let getResponse = await axios.get(baseUrl + url, {
    headers: {
      Authorization: `Bearer ${localStorageKey}`,
      "P-DeviceType": "web",
    },
  });
  return getResponse ? getResponse.data : null;
};

export let DownloadFile = async (url) => {
  let getResponse = await axios.get(url, {
    responseType: "blob",
  });
  return getResponse ? getResponse.data : null;
};

//Post Method
export let PostMethod = async (url, data) => {
  let localStorageKey = localStorage.getItem("pkt-auth-key");
  let postData = await axios.post(baseUrl + url, data, {
    headers: {
      Authorization: `Bearer ${localStorageKey}`,
      "P-DeviceType": "web",
    },
  });
  return postData ? postData.data : null;
};

export let LoginMethod = async (url, data) => {
  let postData = await axios.post(baseUrl + url, data, {
    headers: { "P-DeviceType": "web" },
  });
  return postData ? postData.data : null;
};

export let PostFormMethod = async (url, data) => {
  let localStorageKey = localStorage.getItem("pkt-auth-key");
  let postData = await axios.post(baseUrl + url, data, {
    headers: {
      Authorization: `Bearer ${localStorageKey}`,
      "P-DeviceType": "web",
      "Content-Type": "multipart/form-data",
    },
  });
  return postData ? postData.data : null;
};

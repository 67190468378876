import { Dialog, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { RiFileCopyFill } from "react-icons/ri";
import create from "zustand";
import * as Utils from "../../CommonServices/Utils";
const statusStore = create((set) => ({
  dialogDetails: {},
  msg: null,
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));

const StatusDialog = () => {
  const { dialogDetails, msg, onSubmit, close } = statusStore();
  return (
    <Dialog
      className={dialogDetails?.className}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle>
        <div className="d-flex flex-row align-items-center">
          <span>{dialogDetails?.iconName}</span>
          <div style={{ fontSize: "18px", marginLeft: "10px" }}>
            {dialogDetails?.title}
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div>
          <div>{dialogDetails?.message}</div>
          {Utils.isNullOrUndefined(msg) ? null : (
            <div>
              Referral Code is : {msg}{" "}
              {msg ? (
                <RiFileCopyFill
                  className="pkt-width-16 pkt-height-16 pkt-margin-left-5 pkt-cursor-pointer"
                  onClick={() => {
                    Utils.copyToClipboard(msg);
                  }}
                  title="Copy"
                />
              ) : null}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { StatusDialog };

export const status_dialog = (dialogDetails, msg, onSubmit) => {
  statusStore.setState({ dialogDetails, msg, onSubmit });
};

import React, { useEffect, useState } from "react";
import * as API from "../CommonServices/Services";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
export default function MyProfile() {
  let userName = localStorage.getItem("userName");
  // let userType = localStorage.getItem("userType");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const fetchData = () => {
    setLoading(true);

    let payload = {
      initiatorUserName: "SUPERADMIN",
      searchingForUserName: userName,
    };

    API.PostMethod("/rbac/fetchAdminDetails", payload).then((response) => {
      setData(response?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="d-flex flex-column pkt-height-percentage-100  pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height pkt-padding-20">
        <div style={{ fontWeight: 600, fontSize: "20px" }}>My Profile</div>
        {loading ? (
          <div className="pkt-height-percentage-100 pkt-width-percentage-100">
            <Loader />
          </div>
        ) : (
          <div className="d-flex flex-column pkt-margin-10">
            <hr />
            <div>Name : {data?.userName} </div>
            <div className="pkt-margin-top-10">Email : {data?.emailId}</div>
            <div className="pkt-margin-top-10">
              Mobile : {data?.phoneNumber}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

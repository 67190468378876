import { AppBar, Toolbar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useContext, useState } from "react";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { Context } from "../CommonServices/Context";
function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let navigate = useNavigate();
  const [context, setContext] = useContext(Context);
  let userType = localStorage.getItem("userType");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("pkt-auth-key");
    localStorage.removeItem("userType");
    localStorage.removeItem("userName");
    setContext({});
    navigate("/login");
  };

  const dashboard = () => {
    navigate("/dashboard");
  };
  const collections = () => {
    navigate("/collections");
  };
  const pockets = () => {
    console.log('lll')
    navigate("/pockets");
  };
  const requests = () => {
    navigate("/requests");
  };
  const profile = () => {
    setAnchorEl(null);
    navigate("/my-profile");
  };
  const member = () => {
    setAnchorEl(null);
    navigate("/members");
  };

  const subBroker = () => {
    setAnchorEl(null);
    navigate("/subBroker");
  };

  return (
    <React.Fragment>
      <AppBar className="pkt-height-45">
        <div className="d-flex flex-row justify-content-between align-items-center pkt-padding-10">
          <div className="d-flex flex-row">
            <div
              className="pkt-cursor-pointer pkt-margin-left-20"
              onClick={dashboard}
            >
              Dashboard
            </div>
            {userType != "SUPERADMIN" ? null : (
              <div
                className="pkt-cursor-pointer pkt-margin-left-20"
                onClick={collections}
              >
                Collections
              </div>
            )}
            {userType != "SUPERADMIN" ? null : (
              <div
                className="pkt-cursor-pointer pkt-margin-left-20"
                onClick={pockets}
              >
                Pockets
              </div>
            )}
            {userType != "SUPERADMIN" ? null : (
              <div
                className="pkt-cursor-pointer pkt-margin-left-20"
                onClick={requests}
              >
                Modification Requests
              </div>
            )}
          </div>
          <CgProfile
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            className="pkt-cursor-pointer pkt-height-25 pkt-width-25 pkt-margin-right-10"
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={profile}>My Profile</MenuItem>
            {userType != "SUPERADMIN" ? null : (
              <MenuItem onClick={member}>Member's</MenuItem>
            )}
            {userType != "SUPERADMIN" ? null : (
              <MenuItem onClick={subBroker}>Sub Broker's</MenuItem>
            )}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </AppBar>
      <Toolbar className="pkt-min-height-45 pkt-max-height-45" />
    </React.Fragment>
  );
}

export default Header;

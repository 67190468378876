import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import { default as React, useContext, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Context } from "../CommonServices/Context";
import * as API from "../CommonServices/Services";
import brokerLogo from "../Assets/broker-logo.png";
import * as Utils from "../CommonServices/Utils";
function Login() {
  let navigate = useNavigate();
  const [showEye, updateEye] = useState(true);
  const [loginFailedErrorMessage, setLoginFailedErrorMessage] = useState(null);
  const [loginLoader, setLoginLoader] = useState(false);
  const [context, setContext] = useContext(Context);
  const toggleEye = () => {
    updateEye(!showEye);
  };
  function emailChange(event) {
    loginForm.setFieldValue("userId", event.target.value);
    setLoginFailedErrorMessage(null);
  }

  function passwordChange(event) {
    loginForm.setFieldValue("password", event.target.value);
    setLoginFailedErrorMessage(null);
  }
  function typeChange(event) {
    loginForm.setFieldValue("type", event.target.value);
    setLoginFailedErrorMessage(null);
  }

  const loginForm = useFormik({
    initialValues: {
      userId: "",
      password: "",
      type: 1,
    },
    validationSchema: Yup.object({
      userId: Yup.string().required("User name is required!"),
      password: Yup.string().required("Password is required!"),
    }),
    onSubmit: (values) => {
      setLoginLoader(true);
      if (values.type === 2) {
        values.userId = values.userId + "_2";
      }
      if (values.type === 3) {
        values.userId = values.userId + "_3";
      }
      API.LoginMethod("/rbac/adminLogin", values)
        .then((response) => {
          if (values.type === 2 || values.type === 3) {
            values.userId = values.userId.slice(0, -2);
          }
          if (response?.status === true) {
            setLoginLoader(false);
            localStorage.setItem("pkt-auth-key", response?.data?.authToken);
            localStorage.setItem("userType", response?.data?.userType);
            localStorage.setItem("userName", values?.userId);
            setContext(response?.data);
            navigate("/dashboard");
          }
        })
        .catch(function (error) {
          if (values.type === 2 || values.type === 3) {
            values.userId = values.userId.slice(0, -2);
          }
          setContext(error?.response?.data);
          setLoginFailedErrorMessage(error?.response?.data?.message);
          setLoginLoader(false);
        });
    },
  });

  return (
    <div
      className="d-flex flex-column"
      style={{
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div>
        {" "}
        <img
          src={brokerLogo}
          alt="logo"
          style={{ height: "68px", width: "253px" }}
        />
      </div>
      <form onSubmit={loginForm.handleSubmit}>
        <div className="d-flex flex-column pkt-margin-top-30">
          <div className="d-flex flex-column">
            <FormLabel>
              <div
                style={{
                  color: "#100563",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                User Type<sup style={{ color: "red" }}>*</sup>
              </div>
            </FormLabel>
            <FormControl size="small">
              <Select
                className="pkt-height-40 w-100"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={loginForm.values.type}
                onChange={typeChange}
                name="type"
                defaultValue={1}
                onBlur={loginForm.handleBlur}
              >
                <MenuItem value={1}>Super Admin</MenuItem>
                <MenuItem value={2}>Admin</MenuItem>
                <MenuItem value={3}>Agent</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <FormLabel>
              <div
                style={{
                  color: "#100563",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Username<sup style={{ color: "red" }}>*</sup>
              </div>
            </FormLabel>
            <TextField
              className="w-100"
              id="outlined-size-small"
              size="small"
              name="userId"
              placeholder="Enter user name"
              value={loginForm.values.userId}
              onChange={emailChange}
              error={
                loginForm.touched.userId && loginForm.errors.userId
                  ? true
                  : false
              }
              onBlur={loginForm.handleBlur}
            />
            <div>
              {loginForm.touched.userId && loginForm.errors.userId ? (
                <span className="pkt-error">{loginForm.errors.userId}</span>
              ) : null}
            </div>
          </div>
          <div>
            <FormLabel>
              <div
                style={{
                  color: "#100563",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
                Password<sup style={{ color: "red" }}>*</sup>
              </div>
            </FormLabel>
            <TextField
              className="w-100"
              type={showEye ? "password" : "text"}
              id="outlined-size-small"
              size="small"
              name="password"
              placeholder="Enter password"
              value={loginForm.values.password}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={passwordChange}
              onBlur={loginForm.handleBlur}
              error={
                loginForm.touched.password &&
                loginForm.errors.password
                  ? true
                  : false
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showEye ? (
                      <AiFillEye onClick={toggleEye} />
                    ) : (
                      <AiFillEyeInvisible onClick={toggleEye} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <div>
              {loginForm.touched.password &&
              loginForm.errors.password ? (
                <span className="pkt-error">
                  {loginForm.errors.password}
                </span>
              ) : null}{" "}
            </div>
          </div>
          {!Utils.isNullOrUndefined(loginFailedErrorMessage) ? (
            <div className="pkt-error">{loginFailedErrorMessage}</div>
          ) : (
            <div></div>
          )}
          <Button
            variant="contained"
            style={{ align: "center", marginTop: "10px" }}
            type="submit"
            disabled={!Utils.isNullOrUndefined(loginFailedErrorMessage)}
          >
            {!loginLoader ? (
              "Login"
            ) : (
              <Box className="d-flex">
                <CircularProgress size="1.3rem" sx={{ color: "white" }} />
              </Box>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default Login;

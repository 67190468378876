import { FiThumbsUp } from "react-icons/fi";
import { MdOutlineErrorOutline } from "react-icons/md";
export function isNullOrUndefined(value) {
  return value === null || value === undefined || value.length === 0;
}

export const state_district =
  "https://docs.google.com/spreadsheets/d/1RrXRs1juTQUDgSFT6azSiTIhZcdX-D4XCZb2hHpbpEk/edit?usp=sharing";

export const STATUS_POPUP_DETAILS = {
  success: {
    iconName: <FiThumbsUp size="1.4rem" />,
    title: "Approved",
    message: "",
    className: "pkt-success-dialog",
  },
  error: {
    iconName: <MdOutlineErrorOutline size="1.4rem" />,
    title: "Rejected",
    message: "",
    className: "pkt-error-dialog",
  },
};

export const reject_data = [
  {
    docType: "PAN",
    rejectionReason: "",
    isRejected: false,
  },
  {
    docType: "AADHAAR",
    rejectionReason: "",
    isRejected: false,
  },
  {
    docType: "SELFIE",
    rejectionReason: "",
    isRejected: false,
  },
  {
    docType: "VIDEO",
    rejectionReason: "",
    isRejected: false,
  },
  {
    docType: "SIGNATURE",
    rejectionReason: "",
    isRejected: false,
  },
  {
    docType: "BANKSTATEMENT",
    rejectionReason: "",
    isRejected: false,
  },
];

export const checkbox_detail = [
  {
    docType: "PAN",
    rejectionReason: "",
  },
];

export const status = "";

export const RejectedReasons = [
  "Rejected due to bad image",
  "Document not Valid",
  "Image is not clear",
  "Selfie image not clear",
  "Selfie not matching with other docs",
  "Video is not clear",
  "Bank account is Invalid",
  "Name of bank account is different from the Documents",
  "Other",
];

export function allFormUncheched(value) {
  let result = true;
  for (let i = 0; i < value.length; i++) {
    if (value[i].isRejected === true) {
      result = false;
      break;
    } else {
    }
  }
  return result;
}

export const Role = ["Admin", "Agent", "KYC", "Send Notification"];
export const RoleUpdated = [
  { key: 2, name: "Admin" },
  { key: 3, name: "Agent" },
  { key: 4, name: "KYC" },
  { key: 5, name: "Send Notification" },
];
export const copyToClipboard = (text) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const DateTimeFormat = (date) => {
  const DATE_TIME_OPTIONS = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", DATE_TIME_OPTIONS).format(
    new Date(date)
  );
};

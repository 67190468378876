export const STATES_DISTRICTS = [
  {
    state: "ANDAMAN & NICOBAR ISLANDS",
    districts: ["South Andaman", , "Nicobar", "North And Middle Andaman"],
  },
  {
    state: "ANDHRA PRADESH",
    districts: [
      "East Godavari",
      "Ananthapur",
      "Cuddapah",
      "Chittoor",
      "Kurnool",
      "Guntur",
      "Prakasam",
      "Visakhapatnam",
      "Nellore",
      "Srikakulam",
      "Vizianagaram",
      "West Godavari",
    ],
  },
  {
    state: "ARUNACHAL PRADESH",
    districts: [
      "West Kameng",
      "Tawang",
      "Papum Pare",
      "East Siang",
      "Lohit",
      "Changlang",
      "East Kameng",
      "Upper Siang",
      "Tirap",
      "Upper Subansiri",
      "Lower Subansiri",
      "Kurung Kumey",
      "Dibang Valley",
      "West Siang",
      "Lower Dibang Valley",
    ],
  },
  {
    state: "ASSAM",
    districts: [
      "Kamrup",
      "Barpeta",
      "Sibsagar",
      "Karbi Anglong",
      "Lakhimpur",
      "Nagaon",
      "Tinsukia",
      "Nalbari",
      "Jorhat",
      "Sonitpur",
      "Golaghat",
      "Dibrugarh",
      "Dhubri",
      "Bongaigaon",
      "Darrang",
      "Marigaon",
      "Kokrajhar",
      "Dhemaji",
      "Goalpara",
      "Cachar",
      "Karimganj",
      "Hailakandi",
      "North Cachar Hills",
    ],
  },
  {
    state: "BIHAR",
    districts: [
      "Patna",
      "Buxar",
      "Gaya",
      "Jehanabad",
      "Aurangabad(BH)",
      "Lakhisarai",
      "Nalanda",
      "Bhojpur",
      "Rohtas",
      "Banka",
      "Bhagalpur",
      "Nawada",
      "Kaimur (Bhabua)",
      "Arwal",
      "Jamui",
      "Munger",
      "Khagaria",
      "Madhepura",
      "Sheikhpura",
      "Siwan",
      "Saran",
      "Sitamarhi",
      "Vaishali",
      "Gopalganj",
      "Muzaffarpur",
      "Sheohar",
      "Begusarai",
      "East Champaran",
      "Madhubani",
      "West Champaran",
      "Katihar",
      "Darbhanga",
      "Supaul",
      "Araria",
      "Saharsa",
      "Samastipur",
      "Purnia",
      "Kishanganj",
    ],
  },
  { state: "CHANDIGARH", districts: ["Chandigarh"] },
  {
    state: "CHATTISGARH",
    districts: [
      "Korba",
      "Durg",
      "Rajnandgaon",
      "Raipur",
      "Bastar",
      "Bilaspur(CGH)",
      "Raigarh",
      "Mahasamund",
      "Dantewada",
      "Janjgir-champa",
      "Koriya",
      "Kanker",
      "Bijapur(CGH)",
      "Kawardha",
      "Dhamtari",
      "Surguja",
      "Jashpur",
      "Gariaband",
      "Narayanpur",
    ],
  },
  { state: "DADRA & NAGAR HAVELI", districts: ["Dadra & Nagar Haveli"] },
  { state: "DAMAN & DIU", districts: ["Diu", "Daman"] },
  {
    state: "DELHI",
    districts: [
      "Central Delhi",
      "South West Delhi",
      "North West Delhi",
      "South Delhi",
      "West Delhi",
      "East Delhi",
      "North East Delhi",
      "New Delhi",
      "North Delhi",
    ],
  },
  { state: "GOA", districts: ["North Goa", "South Goa"] },
  {
    state: "GUJARAT",
    districts: [
      "Rajkot",
      "Jamnagar",
      "Porbandar",
      "Junagadh",
      "Surendra Nagar",
      "Ahmedabad",
      "Mahesana",
      "Banaskantha",
      "Amreli",
      "Anand",
      "Kachchh",
      "Sabarkantha",
      "Bhavnagar",
      "Gandhi Nagar",
      "Kheda",
      "Vadodara",
      "Bharuch",
      "Surat",
      "Dahod",
      "Panch Mahals",
      "Valsad",
      "Tapi",
      "Patan",
      "Narmada",
      "Navsari",
      "The Dangs",
    ],
  },
  {
    state: "HARYANA",
    districts: [
      "Jhajjar",
      "Karnal",
      "Panipat",
      "Mahendragarh",
      "Hisar",
      "Sirsa",
      "Panchkula",
      "Sonipat",
      "Faridabad",
      "Jind",
      "Gurgaon",
      "Kaithal",
      "Ambala",
      "Bhiwani",
      "Yamuna Nagar",
      "Rohtak",
      "Kurukshetra",
      "Fatehabad",
      "Rewari",
    ],
  },
  {
    state: "HIMACHAL PRADESH",
    districts: [
      "Solan",
      "Shimla",
      "Bilaspur (HP)",
      "Mandi",
      "Chamba",
      "Una",
      "Kangra",
      "Kullu",
      "Hamirpur(HP)",
      "Kinnaur",
      "Sirmaur",
      "Lahul & Spiti",
    ],
  },
  {
    state: "JAMMU & KASHMIR",
    districts: [
      "Jammu",
      "Srinagar",
      "Kathua",
      "Pulwama",
      "Udhampur",
      "Rajauri",
      "Doda",
      "Baramulla",
      "Reasi",
      "Leh",
      "Ananthnag",
      "Budgam",
      "Poonch",
      "Kupwara",
      "Kargil",
      "Bandipur",
      "Shopian",
      "Kulgam",
    ],
  },
  {
    state: "JHARKHAND",
    districts: [
      "Palamau",
      "Godda",
      "Dumka",
      "Garhwa",
      "Giridh",
      "Deoghar",
      "Sahibganj",
      "Bokaro",
      "Ranchi",
      "Koderma",
      "West Singhbhum",
      "Simdega",
      "Jamtara",
      "Pakur",
      "Latehar",
      "East Singhbhum",
      "Hazaribag",
      "Dhanbad",
      "Khunti",
      "Ramgarh",
      "Seraikela-kharsawan",
      "Gumla",
      "Lohardaga",
      "Chatra",
    ],
  },
  {
    state: "KARNATAKA",
    districts: [
      "Bangalore Rural",
      "Bangalore",
      "Chikkaballapur",
      "Kolar",
      "Mysore",
      "Ramanagar",
      "Tumkur",
      "Mandya",
      "Dakshina Kannada",
      "Shimoga",
      "Chamrajnagar",
      "Hassan",
      "Udupi",
      "Chickmagalur",
      "Kodagu",
      "Davangere",
      "Dharwad",
      "Chitradurga",
      "Yadgir",
      "Bijapur(KAR)",
      "Gadag",
      "Bellary",
      "Bagalkot",
      "Gulbarga",
      "Bidar",
      "Uttara Kannada",
      "Koppal",
      "Haveri",
      "Raichur",
      "Belgaum",
    ],
  },
  {
    state: "KERALA",
    districts: [
      "Kasargod",
      "Kannur",
      "Kozhikode",
      "Wayanad",
      "Malappuram",
      "Palakkad",
      "Thrissur",
      "Ernakulam",
      "Kottayam",
      "Alappuzha",
      "Pathanamthitta",
      "Idukki",
      "Kollam",
      "Thiruvananthapuram",
    ],
  },
  { state: "LAKSHADWEEP", districts: ["Lakshadweep"] },
  {
    state: "MADHYA PRADESH",
    districts: [
      "Indore",
      "Ratlam",
      "East Nimar",
      "Dewas",
      "Hoshangabad",
      "Barwani",
      "Dhar",
      "Neemuch",
      "West Nimar",
      "Betul",
      "Harda",
      "Bhopal",
      "Mandsaur",
      "Ujjain",
      "Khandwa",
      "Jhabua",
      "Khargone",
      "Burhanpur",
      "Narsinghpur",
      "Chhindwara",
      "Alirajpur",
      "Shahdol",
      "Vidisha",
      "Guna",
      "Gwalior",
      "Balaghat",
      "Sehore",
      "Sagar",
      "Rewa",
      "Shivpuri",
      "Seoni",
      "Mandla",
      "Damoh",
      "Raisen",
      "Shajapur",
      "Katni",
      "Morena",
      "Sheopur",
      "Umaria",
      "Chhatarpur",
      "Bhind",
      "Satna",
      "Rajgarh",
      "Tikamgarh",
      "Jabalpur",
      "Dindori",
      "Anuppur",
      "Datia",
      "Sidhi",
      "Ashok Nagar",
      "Panna",
      "Singrauli",
    ],
  },
  {
    state: "MAHARASHTRA",
    districts: [
      "Mumbai",
      "Thane",
      "Raigarh(MH)",
      "Pune",
      "Satara",
      "Solapur",
      "Ahmed Nagar",
      "Beed",
      "Kolhapur",
      "Osmanabad",
      "Sangli",
      "Sindhudurg",
      "Ratnagiri",
      "Latur",
      "Nashik",
      "Jalna",
      "Dhule",
      "Nagpur",
      "Jalgaon",
      "Parbhani",
      "Chandrapur",
      "Aurangabad",
      "Nanded",
      "Nandurbar",
      "Gondia",
      "Gadchiroli",
      "Wardha",
      "Hingoli",
      "Bhandara",
      "Amravati",
      "Washim",
      "Akola",
      "Yavatmal",
      "Buldhana",
    ],
  },
  {
    state: "MANIPUR",
    districts: [
      "Imphal West",
      "Thoubal",
      "Chandel",
      "Ukhrul",
      "Churachandpur",
      "Senapati",
      "Bishnupur",
      "Imphal East",
      "Tamenglong",
    ],
  },
  {
    state: "MEGHALAYA",
    districts: [
      "Tamenglong",
      "East Khasi Hills",
      "East Garo Hills",
      "Jaintia Hills",
      "West Khasi Hills",
      "Ri Bhoi",
      "South Garo Hills",
      "West Garo Hills",
    ],
  },
  {
    state: "MIZORAM",
    districts: [
      "Aizawl",
      "Mammit",
      "Lunglei",
      "Lawngtlai",
      "Kolasib",
      "Serchhip",
      "Champhai",
      "Saiha",
    ],
  },
  {
    state: "NAGALAND",
    districts: [
      "Kohima",
      "Wokha",
      "Mokokchung",
      "Phek",
      "Tuensang",
      "Mon",
      "Dimapur",
      "Zunhebotto",
      "Peren",
      "Kiphire",
      "Longleng",
    ],
  },
  {
    state: "ODISHA",
    districts: [
      "Mayurbhanj",
      "Kendujhar",
      "Khorda",
      "Jagatsinghapur",
      "Bhadrak",
      "Kendrapara",
      "Baleswar",
      "Cuttack",
      "Jajapur",
      "Puri",
      "Nayagarh",
      "Angul",
      "Dhenkanal",
      "Nuapada",
      "Balangir",
      "Sambalpur",
      "Jharsuguda",
      "Gajapati",
      "Ganjam",
      "Kalahandi",
      "Bargarh",
      "Sundergarh",
      "Koraput",
      "Kandhamal",
      "Malkangiri",
      "Rayagada",
      "Boudh",
      "Nabarangapur",
      "Sonapur",
      "Debagarh",
    ],
  },
  { state: "PONDICHERRY", districts: ["Pondicherry", "Karaikal", "Mahe"] },
  {
    state: "PUNJAB",
    districts: [
      "Rupnagar",
      "Fatehgarh Sahib",
      "Mohali",
      "Ropar",
      "Patiala",
      "Tarn Taran",
      "Kapurthala",
      "Ludhiana",
      "Moga",
      "Amritsar",
      "Hoshiarpur",
      "Sangrur",
      "Barnala",
      "Bathinda",
      "Jalandhar",
      "Gurdaspur",
      "Firozpur",
      "Nawanshahr",
      "Faridkot",
      "Pathankot",
      "Muktsar",
      "Fazilka",
      "Mansa",
    ],
  },
  {
    state: "RAJASTHAN",
    districts: [
      "Alwar",
      "Jaipur",
      "Dausa",
      "Sirohi",
      "Ajmer",
      "Bharatpur",
      "Pali",
      "Baran",
      "Chittorgarh",
      "Rajsamand",
      "Kota",
      "Bhilwara",
      "Udaipur",
      "Sawai Madhopur",
      "Bundi",
      "Jhalawar",
      "Dungarpur",
      "Tonk",
      "Banswara",
      "Sikar",
      "Jhujhunu",
      "Churu",
      "Nagaur",
      "Jalor",
      "Karauli",
      "Jodhpur",
      "Barmer",
      "Bikaner",
      "Ganganagar",
      "Jaisalmer",
      "Hanumangarh",
      "Dholpur",
    ],
  },
  {
    state: "SIKKIM",
    districts: ["East Sikkim", "West Sikkim", "North Sikkim", "South Sikkim"],
  },
  {
    state: "TAMIL NADU",
    districts: [
      "Chennai",
      "Villupuram",
      "Kanchipuram",
      "Tiruvannamalai",
      "Tiruvallur",
      "Cuddalore",
      "Vellore",
      "Nagapattinam",
      "Tiruchirappalli",
      "Tiruvarur",
      "Thanjavur",
      "Perambalur",
      "Ariyalur",
      "Pudukkottai",
      "Sivaganga",
      "Karur",
      "Namakkal",
      "Salem",
      "Ramanathapuram",
      "Madurai",
      "Virudhunagar",
      "Dindigul",
      "Kanyakumari",
      "Tuticorin",
      "Theni",
      "Tirunelveli",
      "Coimbatore",
      "Krishnagiri",
      "Erode",
      "Dharmapuri",
      "Nilgiris",
    ],
  },
  {
    state: "TELANGANA",
    districts: [
      "K.V.Rangareddy",
      "Hyderabad",
      "Medak",
      "Warangal",
      "Adilabad",
      "Khammam",
      "Mahabub Nagar",
      "Karim Nagar",
      "Nalgonda",
      "Nizamabad",
    ],
  },
  {
    state: "TRIPURA",
    districts: ["South Tripura", "North Tripura", "West Tripura", "Dhalai"],
  },
  {
    state: "UTTAR PRADESH",
    districts: [
      "Aligarh",
      "Bulandshahr",
      "Gautam Buddha Nagar",
      "Mainpuri",
      "Etah",
      "Hathras",
      "Etawah",
      "Kanpur Nagar",
      "Ghaziabad",
      "Auraiya",
      "Kanpur Dehat",
      "Unnao",
      "Agra",
      "Firozabad",
      "Kannauj",
      "Farrukhabad",
      "Azamgarh",
      "Jaunpur",
      "Ambedkar Nagar",
      "Varanasi",
      "Barabanki",
      "Ballia",
      "Hamirpur",
      "Allahabad",
      "Fatehpur",
      "Kaushambi",
      "Chitrakoot",
      "Sant Ravidas Nagar",
      "Faizabad",
      "Lucknow",
      "Banda",
      "Mahoba",
      "Sultanpur",
      "Mau",
      "Chandauli",
      "Pratapgarh",
      "Moradabad",
      "Ghazipur",
      "Raebareli",
      "Sonbhadra",
      "Hardoi",
      "Bijnor",
      "Budaun",
      "Bareilly",
      "Mirzapur",
      "Jyotiba Phule Nagar",
      "Rampur",
      "Shahjahanpur",
      "Pilibhit",
      "Meerut",
      "Saharanpur",
      "Bagpat",
      "Balrampur",
      "Gonda",
      "Shrawasti",
      "Sitapur",
      "Kheri",
      "Kushinagar",
      "Muzaffarnagar",
      "Sant Kabir Nagar",
      "Maharajganj",
      "Deoria",
      "Jalaun",
      "Mathura",
      "Bahraich",
      "Siddharthnagar",
      "Basti",
      "Jhansi",
      "Gorakhpur",
      "Lalitpur",
    ],
  },
  {
    state: "UTTARAKHAND",
    districts: [
      "Chamoli",
      "Pauri Garhwal",
      "Rudraprayag",
      "Nainital",
      "Udham Singh Nagar",
      "Almora",
      "Dehradun",
      "Tehri Garhwal",
      "Haridwar",
      "Champawat",
      "Bageshwar",
      "Uttarkashi",
      "Pithoragarh",
    ],
  },
  {
    state: "WEST BENGAL",
    districts: [
      "Kolkata",
      "Hooghly",
      "North 24 Parganas",
      "South 24 Parganas",
      "Howrah",
      "Bardhaman",
      "Birbhum",
      "East Midnapore",
      "West Midnapore",
      "Puruliya",
      "Bankura",
      "Darjiling",
      "Nadia",
      "Jalpaiguri",
      "South Dinajpur",
      "Malda",
      "Cooch Behar",
      "Murshidabad",
      "North Dinajpur",
      "Medinipur",
    ],
  },
];

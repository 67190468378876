import React, { useState } from "react";
import "./App.css";
import Routing from "./CommonServices/Routing";
import { AddProfileDialog } from "./Components/Dialogs/AddProfileialog";
import { ProfileDialog } from "./Components/Dialogs/ProfileDialog";
import { RejectDialog } from "./Components/Dialogs/RejectDialog";
import { StatusDialog } from "./Components/Dialogs/StatusDialog";
import { Context } from "../src/CommonServices/Context";
import { ConfirmationDialog } from "./Components/Dialogs/ConfirmationDialog";
import { SubBrokerDialog } from "./Components/Dialogs/SubBrokerDialog";
import { ModificationDialog } from "./Components/Dialogs/ModificationDialog";

function App() {
  const [context, setContext] = useState({});
  return (
    <Context.Provider value={[context, setContext]}>
      <div className="pkt-height-percentage-100 pkt-overflow-hidden">
        {" "}
        <Routing />
        <ProfileDialog />
        <StatusDialog />
        <RejectDialog />
        <AddProfileDialog />
        <SubBrokerDialog />
        <ConfirmationDialog />
        <ModificationDialog />
      </div>
    </Context.Provider>
  );
}

export default App;

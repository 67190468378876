import { Dialog, DialogTitle, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { MdOutlineClose } from "react-icons/md";
import create from "zustand";
const modificationStore = create((set) => ({
  data: undefined,
  onSubmit: undefined,
  close: () => {
    set({
      onSubmit: undefined,
    });
  },
}));

const ModificationDialog = () => {
  const { data, onSubmit, close } = modificationStore();
  const onReject = () => {
    // reject_dialog("Reject Dialog", () => {
    //   let payload = {
    //     approve: false,
    //     rejectedDoc: Utils.reject_data,
    //     userid: data?.userid,
    //   };
    //   API.PostMethod("/admin/docApproveOrReject", payload).then((response) => {
    //     let status_details = {
    //       ...Utils.STATUS_POPUP_DETAILS.error,
    //       message: "Profile has been rejected",
    //     };
    //     status_dialog(status_details, null, () => {
    //       for (let i = 0; i < Utils.reject_data.length; i++) {
    //         Utils.reject_data[i].isRejected = false;
    //         Utils.reject_data[i].rejectionReason = "";
    //       }
    //       close();
    //       reload();
    //     });
    //   });
    // });
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "60%",
          height: "60%",
          maxWidth: "100%",
        },
      }}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle className="d-flex flex-row justify-content-between align-items-center">
        <div>Modification Request</div>
        <IconButton
          onClick={() => {
            close();
            // addSubBrokerForm.resetForm();
            // setLoginFailedErrorMessage(null);
          }}
        >
          <MdOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column col-6">
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Name : </div>
              <div style={{ marginLeft: "15px" }}>Ayush</div>
            </div>
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Email : </div>
              <div style={{ marginLeft: "15px" }}> ayush@gmail.com</div>
            </div>{" "}
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Phone No. : </div>
              <div style={{ marginLeft: "15px" }}>9131688156</div>
            </div>{" "}
          </div>
          <div className="d-flex flex-column col-6">
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Request Type : </div>
              <div style={{ marginLeft: "15px" }}>Mobile No. Change</div>
            </div>{" "}
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Status : </div>
              <div style={{ marginLeft: "15px" }}>Pending</div>
            </div>
          </div>
        </div>
        <div className="pkt-margin-top-10">
          <span className="pkt-font-size-20 pkt-text-underline">Document Uploaded</span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onReject();
          }}
          variant="outlined"
          color="error"
        >
          Reject
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ModificationDialog };

export const modification_dialog = (data, onSubmit) => {
  modificationStore.setState({ data, onSubmit });
};

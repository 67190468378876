import ExpandMoreIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Button, IconButton, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import fileDownload from "js-file-download";
import React, { useEffect, useState } from "react";
import { AiOutlineCamera, AiOutlineVideoCamera } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { MdOutlineClose, MdPictureAsPdf } from "react-icons/md";
import { Document, Page, pdfjs } from "react-pdf";
import create from "zustand";
import * as API from "../../CommonServices/Services";
import * as Utils from "../../CommonServices/Utils";
import { reject_dialog } from "./RejectDialog";
import { status_dialog } from "./StatusDialog";
const useProfileDialogStore = create((set) => ({
  data: "",
  PDFthumbnail: {},
  onSubmit: undefined,
  reload: undefined,
  close: () => {
    for (let i = 0; i < Utils.reject_data.length; i++) {
      Utils.reject_data[i].isRejected = false;
      Utils.reject_data[i].rejectionReason = "";
    }
    set({
      onSubmit: undefined,
    });
  },
}));

const ProfileDialog = () => {
  const { data, PDFthumbnail, onSubmit, reload, close } =
    useProfileDialogStore();
  const [thumbnail, setThumbnail] = useState();
  const [aadharthumbnail, setaadharThumbnail] = useState();
  const [panthumbnail, setPanThumbnail] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [enumPages, setENumPages] = useState(null);
  const [epageNumber, setEPageNumber] = useState(1);
  const [aadnumPages, setaadNumPages] = useState(null);
  const [aadpageNumber, setaadPageNumber] = useState(1);
  const [pannumPages, setpanNumPages] = useState(null);
  const [panpageNumber, setpanPageNumber] = useState(1);
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function onEDocumentLoadSuccess({ numPages }) {
    setENumPages(numPages);
    setEPageNumber(1);
  }

  function echangePage(offset) {
    setEPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function epreviousPage() {
    echangePage(-1);
  }
  function enextPage() {
    echangePage(1);
  }

  function aadnextPage() {
    aadchangePage(1);
  }

  function onaadDocumentLoadSuccess({ numPages }) {
    setaadNumPages(numPages);
    setaadPageNumber(1);
  }

  function aadchangePage(offset) {
    setaadPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function aadpreviousPage() {
    aadchangePage(-1);
  }

  function pannextPage() {
    panchangePage(1);
  }

  function onpanDocumentLoadSuccess({ numPages }) {
    setpanNumPages(numPages);
    setpanPageNumber(1);
  }

  function panchangePage(offset) {
    setpanPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function panpreviousPage() {
    panchangePage(-1);
  }

  const onReject = () => {
    reject_dialog("Reject Dialog", () => {
      let payload = {
        approve: false,
        rejectedDoc: Utils.reject_data,
        userid: data?.userid,
      };
      API.PostMethod("/admin/docApproveOrReject", payload).then((response) => {
        let status_details = {
          ...Utils.STATUS_POPUP_DETAILS.error,
          message: "Profile has been rejected",
        };
        status_dialog(status_details, null, () => {
          for (let i = 0; i < Utils.reject_data.length; i++) {
            Utils.reject_data[i].isRejected = false;
            Utils.reject_data[i].rejectionReason = "";
          }
          close();
          reload();
        });
      });
    });
  };

  const downloadFiles = (event, data, name) => {
    event.stopPropagation();
    API.DownloadFile(data).then((response) => {
      fileDownload(response, name);
    });
  };
  useEffect(() => {
    // console.log("kkkkkkkkk", data);
    // setLoading(false);
    // if (data) {
    //   console.log("kkkkkk");
    //   console.log(data);
    //   generateThumbnails(data?.awsFileLocations?.bankStatement);
    //   generatePanThumbnails(data?.panDetails?.PanFrontLocation);
    //   generateAadharThumbnails(data?.addressProofDetails?.frontLocation);
    // }
  }, []);

  // async function generateThumbnails(data) {
  //   try {
  //     const thumbnails = await generatePdfThumbnails(data, 100);
  //     setThumbnail(thumbnails[0]?.thumbnail);
  //   } catch (err) {}
  // }
  // async function generateAadharThumbnails(data) {
  //   try {
  //     const thumbnails = await generatePdfThumbnails(data, 100);
  //     setaadharThumbnail(thumbnails[0]?.thumbnail);
  //   } catch (err) {}
  // }
  // async function generatePanThumbnails(data) {
  //   try {
  //     const thumbnails = await generatePdfThumbnails(data, 100);
  //     setPanThumbnail(thumbnails[0]?.thumbnail);
  //   } catch (err) {}
  // }
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "90%",
          height: "95%",
          maxWidth: "100%",
        },
      }}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        className="d-flex flex-row justify-content-between align-items-center"
      >
        <div
          className="d-flex flex-row justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <div className="d-flex col-6">
            <h3> Profile Details</h3>
          </div>
          <div>
            DigiLocker Verified : <span className="pkt-approve-text">   {data?.addressProofDetails?.digiLockerVerified === true ? "Yes" : "No"}</span>
          </div>
          <div style={{ marginRight: "10px" }}>
            KRA Verified :{" "}
            <span className="pkt-approve-text">
              {data?.panDetails?.kraEnabled === true ? "Yes" : "No"}
            </span>
          </div>
        </div>
        <IconButton onClick={close}>
          <MdOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex flex-row">
          <div className="d-flex flex-column col-6">
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Name : </div>
              <div style={{ marginLeft: "15px" }}>
                {data?.userDetails?.name}
              </div>
            </div>
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Mobile : </div>
              <div style={{ marginLeft: "15px" }}>
                {" "}
                {data?.userDetails?.mobileNo}
              </div>
            </div>{" "}
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Customer ID : </div>
              <div style={{ marginLeft: "15px" }}>
                {" "}
                {data?.userDetails?.name}
              </div>
            </div>{" "}
          </div>
          <div className="d-flex flex-column col-6">
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Email : </div>
              <div style={{ marginLeft: "15px" }}>
                {" "}
                {data?.userDetails?.emailId}
              </div>
            </div>{" "}
            <div className="d-flex flex-row col-12">
              <div className="pkt-font-bold">Status : </div>
              <div
                className="pkt-font-bold"
                style={{
                  marginLeft: "15px",
                  color:
                    data?.userDetails?.kycStatus === "REJECTED"
                      ? "red"
                      : data?.userDetails?.kycStatus === "APPROVED"
                        ? "green"
                        : "orange",
                }}
              >
                {" "}
                {Utils.isNullOrUndefined(status)
                  ? data?.userDetails?.kycStatus
                  : Utils.status}
              </div>
            </div>
          </div>
        </div>
        <div className="pkt-font-bold" style={{ marginTop: "15px" }}>
          Documents
        </div>
        <Accordion style={{ marginTop: "15px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="PAN"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(e) => {
                        Utils.reject_data[0].isRejected =
                          !Utils.reject_data[0].isRejected;
                      }}
                    />
                  }
                />
                {data?.panDetails?.panDigiLockerVerified === true ? (
                  <div className="d-flex flex-row col-4">
                    {Utils.isNullOrUndefined(PDFthumbnail.pan) ? (
                      <span>Pan Unavailable</span>
                    ) : (
                      <div
                        className="d-flex flex-row"
                        style={{ height: "100px", width: "100%" }}
                      >
                        <div className="d-flex justify-content-center pkt-img-div col-12">
                          <div className="d-flex justify-content-center col-11">
                            <img
                              style={{ maxHeight: "100%", maxWidth: "100%" }}
                              src={PDFthumbnail.pan}
                            />
                          </div>{" "}
                          <div className="d-flex col-1 justify-content-end align-items-end">
                            <MdPictureAsPdf></MdPictureAsPdf>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-row col-4">
                    {Utils.isNullOrUndefined(
                      data?.panDetails?.PanFrontLocation
                    ) ? (
                      <span>PAN Image Unavailable</span>
                    ) : (
                      <div
                        className="d-flex flex-row"
                        style={{ height: "100px", width: "100%" }}
                      >
                        <div className="d-flex justify-content-end pkt-img-div col-12">
                          <div className="d-flex justify-content-center col-11">
                            <img
                              style={{ maxHeight: "100%", maxWidth: "100%" }}
                              src={data?.panDetails?.PanFrontLocation}
                            />
                          </div>
                          <div className="d-flex col-1 justify-content-end align-items-end">
                            <AiOutlineCamera></AiOutlineCamera>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  {data?.panDetails?.kraEnabled === true ? (
                    <span className="pkt-approve-text">KRA Enabled</span>
                  ) : (
                    <span className="pkt-approve-text">
                      {data?.panDetails?.panVerified === true
                        ? "[Approved]"
                        : ""}
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: PAN
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(
                        data?.panDetails?.PanFrontLocation
                      )
                        ? "PAN Unavailable "
                        : "Download PAN"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.panDetails?.PanFrontLocation
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          if (
                            data?.panDetails?.panDigiLockerVerified === true
                          ) {
                            downloadFiles(
                              event,
                              data?.panDetails?.PanFrontLocation,
                              "pan_" + data?.panDetails?.panId + ".pdf"
                            );
                          } else {
                            downloadFiles(
                              event,
                              data?.panDetails?.PanFrontLocation,
                              "pan_" + data?.panDetails?.panId + ".jpg"
                            );
                          }
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr style={{ marginTop: "0px" }}></hr>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-3">
                <div className="d-flex flex-row pkt-font-bold justify-content-center">
                  OCR Details
                </div>
                <div
                  className="d-flex flex-row justify-content-start"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex flex-column col-6">
                    <div className="pkt-font-bold">PAN No. :</div>
                    <div className="pkt-font-bold">Name :</div>
                    <div className="pkt-font-bold">Fathers Name :</div>
                    <div className="pkt-font-bold">DOB:</div>
                  </div>
                  <div
                    className="d-flex flex-column col-6"
                    style={{ marginLeft: "10px" }}
                  >
                    <div>
                      {Utils.isNullOrUndefined(data?.panDetails?.panId)
                        ? "-"
                        : data?.panDetails?.panId}
                    </div>
                    <div>
                      {" "}
                      {Utils.isNullOrUndefined(data?.panDetails?.panName)
                        ? "-"
                        : data?.panDetails?.panName}
                    </div>
                    <div>
                      {" "}
                      {Utils.isNullOrUndefined(
                        data?.panDetails?.panMetaData?.fathers_name
                      )
                        ? "-"
                        : data?.panDetails?.panMetaData?.fathers_name}
                    </div>
                    <div>
                      {" "}
                      {Utils.isNullOrUndefined(
                        data?.panDetails?.panMetaData?.dob
                      )
                        ? "-"
                        : data?.panDetails?.panMetaData?.dob}
                    </div>
                  </div>
                </div>
              </div>
              {data?.panDetails?.panDigiLockerVerified === true ? (
                <Document
                  file={data?.panDetails?.PanFrontLocation}
                  onLoadSuccess={onpanDocumentLoadSuccess}
                >
                  <Page pageNumber={panpageNumber} />
                </Document>
              ) : (
                // <object
                //   data={data?.panDetails?.PanFrontLocation}
                //   type="application/pdf"
                //   width="100%"
                //   height="200px"
                // >
                //   {/* <p>
                //     Alternative text - include a link{" "}
                //     <a href="http://africau.edu/images/default/sample.pdf">
                //       to the PDF!
                //     </a>
                //   </p> */}
                // </object>
                <div className="d-flex flex-row flex-row col-9 align-items-center">
                  {Utils.isNullOrUndefined(
                    data?.panDetails?.PanFrontLocation
                  ) ? (
                    <span>PAN Front Image Unavailable</span>
                  ) : (
                    <div className="d-flex flex-row">
                      <div className="pkt-img-div">
                        <img
                          style={{ height: "200px" }}
                          src={data?.panDetails?.PanFrontLocation}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Aadhaar"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(e) => {
                        Utils.reject_data[1].isRejected =
                          !Utils.reject_data[1].isRejected;
                      }}
                    />
                  }
                />
                {data?.addressProofDetails?.digiLockerVerified === true ? (
                  <div className="d-flex flex-row col-4">
                    {Utils.isNullOrUndefined(PDFthumbnail.aadhar) ? (
                      <span>Aadhaar Unavailable</span>
                    ) : (
                      <div
                        className="d-flex flex-row"
                        style={{ height: "100px", width: "100%" }}
                      >
                        <div className="d-flex justify-content-center pkt-img-div col-12">
                          <div className="d-flex justify-content-center col-11">
                            <img
                              style={{ maxHeight: "100%", maxWidth: "100%" }}
                              src={PDFthumbnail.aadhar}
                            />
                          </div>{" "}
                          <div className="d-flex col-1 justify-content-end align-items-end">
                            <MdPictureAsPdf></MdPictureAsPdf>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex flex-row col-4">
                    {Utils.isNullOrUndefined(
                      data?.addressProofDetails?.frontLocation
                    ) ? (
                      <span>Aadhaar Image Unavailable</span>
                    ) : (
                      <div
                        className="d-flex flex-row"
                        style={{ height: "120px", width: "100%" }}
                      >
                        <div className="d-flex justify-content-end pkt-img-div col-12">
                          <div className="d-flex justify-content-center col-11">
                            <img
                              style={{ maxHeight: "100%", maxWidth: "100%" }}
                              src={data?.addressProofDetails?.frontLocation}
                            />
                          </div>
                          <div className="d-flex col-1 justify-content-end align-items-end">
                            <AiOutlineCamera></AiOutlineCamera>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  {data?.panDetails?.kraEnabled === true ? (
                    <span className="pkt-approve-text">KRA Enabled</span>
                  ) : (
                    <span className="pkt-approve-text">
                      {data?.addressProofDetails?.aadharVerified === true
                        ? "[Approved]"
                        : ""}
                    </span>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: Aadhaar
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(
                        data?.addressProofDetails?.frontLocation
                      )
                        ? "Aadhaar Unavailable "
                        : "Download Aadhaar"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.addressProofDetails?.frontLocation
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          if (
                            data?.addressProofDetails?.digiLockerVerified ===
                            true
                          ) {
                            downloadFiles(
                              event,
                              data?.addressProofDetails?.frontLocation,
                              "aadhaar_" + data?.panDetails?.panId + ".pdf"
                            );
                          } else {
                            downloadFiles(
                              event,
                              data?.addressProofDetails?.frontLocation,
                              "aadhaar_" + data?.panDetails?.panId + ".jpg"
                            );
                          }
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetails>
              <hr style={{ marginTop: "0px" }}></hr>
              <div className="d-flex flex-row">
                <div className="d-flex flex-column col-3">
                  <div className="d-flex flex-row pkt-font-bold justify-content-center">
                    OCR Details
                  </div>
                  <div
                    className="d-flex flex-row justify-content-start"
                    style={{ marginTop: "10px" }}
                  >
                    <div className="d-flex flex-column col-6">
                      <div className="pkt-font-bold">ID No. :</div>
                      <div className="pkt-font-bold">Name :</div>
                      <div className="pkt-font-bold">DOB :</div>
                      <div className="pkt-font-bold">Gender :</div>
                      <div className="pkt-font-bold">Address :</div>
                    </div>
                    <div
                      className="d-flex flex-column col-6"
                      style={{ marginLeft: "10px" }}
                    >
                      <div>
                        {Utils.isNullOrUndefined(
                          data?.addressProofDetails?.aadharId
                        )
                          ? "-"
                          : data?.addressProofDetails?.aadharId}
                      </div>
                      <div>
                        {" "}
                        {Utils.isNullOrUndefined(
                          data?.addressProofDetails?.aadharName
                        )
                          ? "-"
                          : data?.addressProofDetails?.aadharName}
                      </div>
                      <div>
                        {" "}
                        {Utils.isNullOrUndefined(
                          data?.addressProofDetails?.aadharMetaData?.dob
                        )
                          ? "-"
                          : data?.addressProofDetails?.aadharMetaData?.dob}
                      </div>
                      <div>
                        {" "}
                        {Utils.isNullOrUndefined(
                          data?.addressProofDetails?.aadharMetaData?.gender
                        )
                          ? "-"
                          : data?.addressProofDetails?.aadharMetaData?.gender}
                      </div>{" "}
                      <div>
                        {" "}
                        {Utils.isNullOrUndefined(
                          data?.addressProofDetails?.aadharMetaData
                            ?.address_information?.address
                        )
                          ? "-"
                          : data?.addressProofDetails?.aadharMetaData
                            ?.address_information?.address}
                      </div>
                    </div>
                  </div>
                </div>
                {data?.addressProofDetails?.digiLockerVerified === true ? (
                  <Document
                    file={data?.addressProofDetails?.frontLocation}
                    onLoadSuccess={onaadDocumentLoadSuccess}
                  >
                    <Page pageNumber={aadpageNumber} />
                  </Document>
                ) : (
                  // <object
                  //   data={data?.addressProofDetails?.frontLocation}
                  //   type="application/pdf"
                  //   width="100%"
                  //   height="200px"
                  // >
                  //   {/* <p>
                  //   Alternative text - include a link{" "}
                  //   <a href="http://africau.edu/images/default/sample.pdf">
                  //     to the PDF!
                  //   </a>
                  // </p> */}
                  // </object>
                  <div className="d-flex flex-row flex-row col-9 align-items-center">
                    {Utils.isNullOrUndefined(
                      data?.addressProofDetails?.frontLocation
                    ) ? (
                      <span>Aadhaar Front Image Unavailable</span>
                    ) : (
                      <div className="d-flex flex-row">
                        <div className="pkt-img-div">
                          <img
                            style={{ height: "200px" }}
                            src={data?.addressProofDetails?.frontLocation}
                          />
                        </div>
                      </div>
                    )}
                    {Utils.isNullOrUndefined(
                      data?.addressProofDetails?.aadharBackLocation
                    ) ? (
                      <span style={{ marginLeft: "10px" }}>
                        {" "}
                        Aadhaar Front Image Unavailable
                      </span>
                    ) : (
                      <div className="d-flex flex-row">
                        <div className="pkt-img-div">
                          <img
                            style={{ height: "200px" }}
                            src={data?.addressProofDetails?.aadharBackLocation}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </AccordionDetails>
        </Accordion>{" "}
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Selfie"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(e) => {
                        Utils.reject_data[2].isRejected =
                          !Utils.reject_data[2].isRejected;
                      }}
                    />
                  }
                />
                <div className="d-flex flex-row col-4">
                  {Utils.isNullOrUndefined(
                    data?.selfieDetails?.selfieS3Location
                  ) ? (
                    <span>Selfie Image Unavailable</span>
                  ) : (
                    <div
                      className="d-flex flex-row"
                      style={{ height: "100px", width: "100%" }}
                    >
                      <div className="d-flex justify-content-end pkt-img-div col-12">
                        <div className="d-flex justify-content-center col-11">
                          <img
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            src={data?.selfieDetails?.selfieS3Location}
                          />
                        </div>
                        <div className="d-flex col-1 justify-content-end align-items-end">
                          <AiOutlineCamera></AiOutlineCamera>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column">
                  <span className="pkt-approve-text">
                    {data?.selfieDetails?.selfieVerified === true
                      ? "[Approved]"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: Selfie
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  {" "}
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(
                        data?.selfieDetails?.selfieS3Location
                      )
                        ? "Selfie Unavailable "
                        : "Download Selfie"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.selfieDetails?.selfieS3Location
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          downloadFiles(
                            event,
                            data?.selfieDetails?.selfieS3Location,
                            "selfie_" + data?.panDetails?.panId + ".jpg"
                          );
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr style={{ marginTop: "0px" }}></hr>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-3">
                <div className="d-flex flex-row pkt-font-bold justify-content-center">
                  Geo Location Info
                </div>
                <div
                  className="d-flex flex-row justify-content-start"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex flex-column col-6">
                    <div className="pkt-font-bold">Latitude :</div>
                    <div className="pkt-font-bold">Longitude :</div>
                  </div>
                  <div
                    className="d-flex flex-column col-6"
                    style={{ marginLeft: "10px" }}
                  >
                    <div>
                      {Utils.isNullOrUndefined(
                        data?.fatcaDetails?.otherDetails?.latitude
                      )
                        ? "-"
                        : data?.fatcaDetails?.otherDetails?.latitude}
                    </div>
                    <div>
                      {" "}
                      {Utils.isNullOrUndefined(
                        data?.fatcaDetails?.otherDetails?.longitude
                      )
                        ? "-"
                        : data?.fatcaDetails?.otherDetails?.longitude}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row flex-row col-9 align-items-center">
                {Utils.isNullOrUndefined(
                  data?.selfieDetails?.selfieS3Location
                ) ? (
                  <span>Selfie Image Unavailable</span>
                ) : (
                  <div className="d-flex flex-row">
                    <div className="pkt-img-div">
                      <img
                        style={{ height: "200px" }}
                        src={data?.selfieDetails?.selfieS3Location}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>{" "}
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Video"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(e) => {
                        Utils.reject_data[3].isRejected =
                          !Utils.reject_data[3].isRejected;
                      }}
                    />
                  }
                />
                <div className="d-flex flex-row col-4">
                  {Utils.isNullOrUndefined(
                    data?.videoOtpDetails?.userVideoS3Location
                  ) ? (
                    <span>Video Unavailable</span>
                  ) : (
                    <div
                      className="d-flex flex-row"
                      style={{ height: "100px", width: "100%" }}
                    >
                      <div
                        style={{ height: "100px" }}
                        className="d-flex justify-content-center pkt-img-div col-12"
                      >
                        <div className="d-flex justify-content-center col-11">
                          <video
                            style={{ height: "80px", width: "100px" }}
                            controls={false}
                            autoPlay={false}
                            src={data?.videoOtpDetails?.userVideoS3Location}
                          ></video>
                        </div>
                        <div className="d-flex col-1 justify-content-end align-items-end">
                          <AiOutlineVideoCamera></AiOutlineVideoCamera>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  {data?.panDetails?.kraEnabled === true ? (
                    <span className="pkt-approve-text">KRA Enabled</span>
                  ) : data?.videoOtpDetails?.userVideoS3Location.length != 0 ?
                    <span className="pkt-approve-text">IPV Enabled</span>
                    : (
                      <span className="pkt-approve-text">
                        {data?.videoOtpDetails?.userVideoVerified === true
                          ? "[Approved]"
                          : ""}
                      </span>
                    )}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: Video
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  {" "}
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(
                        data?.videoOtpDetails?.userVideoS3Location
                      )
                        ? "Video Unavailable "
                        : "Download Video"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.videoOtpDetails?.userVideoS3Location
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          downloadFiles(
                            event,
                            data?.videoOtpDetails?.userVideoS3Location,
                            "video_" + data?.panDetails?.panId + ".mp4"
                          );
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr style={{ marginTop: "0px" }}></hr>
            <div className="d-flex justify-content-center ">
              {Utils.isNullOrUndefined(
                data?.videoOtpDetails?.userVideoS3Location
              ) ? (
                "Video unavailable"
              ) : (
                <div>
                  <div className="d-flex justify-content-center">
                    OTP : {data?.videoOtpDetails?.otpProvidedToUser}
                  </div>
                  <video
                    className="pkt-img-div"
                    style={{ height: "300px", width: "300px" }}
                    controls
                    src={data?.videoOtpDetails?.userVideoS3Location}
                  ></video>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Signature"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(e) => {
                        Utils.reject_data[4].isRejected =
                          !Utils.reject_data[4].isRejected;
                      }}
                    />
                  }
                />
                <div className="d-flex flex-row col-4">
                  {Utils.isNullOrUndefined(
                    data?.signatureDetails?.signatureS3Location
                  ) ? (
                    <span>Signature Unavailable</span>
                  ) : (
                    <div
                      className="d-flex flex-row"
                      style={{ height: "100px", width: "100%" }}
                    >
                      <div className="d-flex justify-content-center pkt-img-div col-12">
                        <div className="d-flex justify-content-center col-11">
                          <img
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            src={data?.signatureDetails?.signatureS3Location}
                          />{" "}
                        </div>{" "}
                        <div className="d-flex col-1 justify-content-end align-items-end">
                          <AiOutlineCamera></AiOutlineCamera>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  <span className="pkt-approve-text">
                    {" "}
                    {data?.signatureDetails?.signatureVerified === true
                      ? "[Approved]"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: Signature
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(
                        data?.signatureDetails?.signatureS3Location
                      )
                        ? "Signature Unavailable "
                        : "Download Signature"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.signatureDetails?.signatureS3Location
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          downloadFiles(
                            event,
                            data?.signatureDetails?.signatureS3Location,
                            "pan_" + data?.panDetails?.panId + ".jpg"
                          );
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr style={{ marginTop: "0px" }}></hr>
            <div className="d-flex flex-row">
              <div className="d-flex flex-row flex-row col-12 align-items-center">
                {Utils.isNullOrUndefined(
                  data?.signatureDetails?.signatureS3Location
                ) ? (
                  <span>Signature Image Unavailable</span>
                ) : (
                  <div className="d-flex flex-row justify-content-center col-12">
                    <div className="pkt-img-div">
                      <img
                        style={{ height: "200px" }}
                        src={data?.signatureDetails?.signatureS3Location}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      name="BankStatement"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      onChange={(e) => {
                        Utils.reject_data[5].isRejected =
                          !Utils.reject_data[5].isRejected;
                      }}
                    />
                  }
                />
                <div className="d-flex flex-row col-4">
                  {Utils.isNullOrUndefined(PDFthumbnail.bankStatement) ? (
                    <span>Bank Details Unavailable</span>
                  ) : (
                    <div
                      className="d-flex flex-row"
                      style={{ height: "100px", width: "100%" }}
                    >
                      <div className="d-flex justify-content-center pkt-img-div col-12">
                        <div className="d-flex justify-content-center col-11">
                          <img
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            src={PDFthumbnail.bankStatement}
                          />
                        </div>{" "}
                        <div className="d-flex col-1 justify-content-end align-items-end">
                          <MdPictureAsPdf></MdPictureAsPdf>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginLeft: "10px" }}
                >
                  <span className="pkt-approve-text">
                    {data?.bankStatementDetails?.verified === true
                      ? "[Approved]"
                      : ""}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: Bank Details
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(
                        data?.awsFileLocations?.bankStatement
                      )
                        ? "Bank Statement Unavailable "
                        : "Download Bank Statement"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.awsFileLocations?.bankStatement
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          downloadFiles(
                            event,
                            data?.awsFileLocations?.bankStatement,
                            "bankStatement_" + data?.panDetails?.panId + ".pdf"
                          );
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr style={{ marginTop: "0px" }}></hr>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-6">
                <div className="d-flex flex-row pkt-font-bold justify-content-center">
                  Bank Account Details
                </div>
                <div
                  className="d-flex flex-row justify-content-start"
                  style={{ marginTop: "10px" }}
                >
                  <div className="d-flex flex-column col-6">
                    <div className="pkt-font-bold">
                      Beneficiary Account No.:
                    </div>
                    <div className="pkt-font-bold">Beneficiary Name :</div>
                    <div className="pkt-font-bold">IFSC :</div>
                    <div className="pkt-font-bold">Amount :</div>
                    <div className="pkt-font-bold">Bank Name:</div>
                  </div>
                  <div
                    className="d-flex flex-column col-6"
                    style={{ marginLeft: "10px" }}
                  >
                    <div>
                      {Utils.isNullOrUndefined(data?.bankAccountDetails?.allBankAccounts) ? '-' :
                        Utils.isNullOrUndefined(
                          data?.bankAccountDetails?.allBankAccounts[0]
                            ?.bankAccountMetadata?.beneficiary_account_no
                        )
                          ? "-"
                          : data?.bankAccountDetails?.allBankAccounts[0]
                            ?.bankAccountMetadata?.beneficiary_account_no
                      }
                    </div>
                    <div>
                      {Utils.isNullOrUndefined(data?.bankAccountDetails?.allBankAccounts) ? '-' : Utils.isNullOrUndefined(
                        data?.bankAccountDetails?.allBankAccounts[0]
                          ?.bankAccountMetadata?.beneficiary_name
                      )
                        ? "-"
                        : data?.bankAccountDetails?.allBankAccounts[0]
                          ?.bankAccountMetadata?.beneficiary_name}
                    </div>
                    <div>
                      {Utils.isNullOrUndefined(data?.bankAccountDetails?.allBankAccounts) ? '-' : Utils.isNullOrUndefined(
                        data?.bankAccountDetails?.allBankAccounts[0]
                          ?.bankAccountMetadata?.beneficiary_ifsc
                      )
                        ? "-"
                        : data?.bankAccountDetails?.allBankAccounts[0]
                          ?.bankAccountMetadata?.beneficiary_ifsc}
                    </div>
                    <div>1</div>
                    <div>
                      {Utils.isNullOrUndefined(data?.bankAccountDetails?.allBankAccounts) ? '-' : Utils.isNullOrUndefined(
                        data?.bankAccountDetails?.allBankAccounts[0]
                          ?.bankAccountMetadata?.bank_name
                      )
                        ? "-"
                        : data?.bankAccountDetails?.allBankAccounts[0]
                          ?.bankAccountMetadata?.bank_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row flex-row col-9 align-items-center">
                {Utils.isNullOrUndefined(
                  data?.awsFileLocations?.bankStatement
                ) ? (
                  <span>Bank Statement Unavailable</span>
                ) : (
                  <div>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-row align-items-center">
                        Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </div>
                      <Button disabled={pageNumber <= 1} onClick={previousPage}>
                        Previous
                      </Button>
                      <Button
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                      >
                        Next
                      </Button>
                    </div>
                    <div
                      className="d-flex flex-row"
                      style={{
                        height: "700px",
                        overflow: "auto",
                        border: "1px solid black",
                      }}
                    >
                      <div>
                        <Document
                          file={data?.awsFileLocations?.bankStatement}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion style={{ marginTop: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div
              className="d-flex flex-row justify-content-start"
              style={{ width: "100%" }}
            >
              <div className="d-flex flex-row col-6 align-items-center">
                {" "}
                <div className="d-flex flex-row col-4">
                  {Utils.isNullOrUndefined(data?.awsFileLocations?.esignDoc) ? (
                    <span> E-Signed Document Unavailable</span>
                  ) : (
                    <div
                      className="d-flex flex-row"
                      style={{ height: "100px", width: "100%" }}
                    >
                      <div className="d-flex justify-content-center pkt-img-div col-12">
                        <div className="d-flex justify-content-center col-11">
                          <img
                            style={{ maxHeight: "100%", maxWidth: "100%" }}
                            src={PDFthumbnail.esignThumbnail}
                          />
                        </div>{" "}
                        <div className="d-flex col-1 justify-content-end align-items-end">
                          <MdPictureAsPdf></MdPictureAsPdf>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between col-6">
                <div className="d-flex align-items-center pkt-font-bold">
                  ID Type: E-Signed Document
                </div>{" "}
                <div
                  className="d-flex align-items-center pkt-cursor-pointer"
                  style={{ marginRight: "10px" }}
                >
                  <Tooltip
                    title={
                      Utils.isNullOrUndefined(data?.awsFileLocations?.esignDoc)
                        ? "E-Signed Document Unavailable "
                        : "Download:E-Signed Document"
                    }
                  >
                    <span>
                      <IconButton
                        disabled={
                          Utils.isNullOrUndefined(
                            data?.awsFileLocations?.esignDoc
                          )
                            ? true
                            : false
                        }
                        onClick={(event) => {
                          downloadFiles(
                            event,
                            data?.awsFileLocations?.esignDoc,
                            "esigndoc_" + data?.panDetails?.panId + ".pdf"
                          );
                        }}
                        sx={{ height: "33px", width: "33px" }}
                      >
                        <BsDownload />
                      </IconButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <hr style={{ marginTop: "0px" }}></hr>
            <div className="d-flex flex-row">
              <div className="d-flex flex-column col-3">
                <div className="d-flex flex-row pkt-font-bold justify-content-center">
                  E-Signed Document
                </div>
              </div>
              <div className="d-flex flex-row flex-row col-9 align-items-center">
                {Utils.isNullOrUndefined(data?.awsFileLocations?.esignDoc) ? (
                  <span>E-Signed Document Unavailable</span>
                ) : (
                  <div>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-row align-items-center">
                        Page {epageNumber || (enumPages ? 1 : "--")} of{" "}
                        {enumPages || "--"}
                      </div>
                      <Button
                        disabled={epageNumber <= 1}
                        onClick={epreviousPage}
                      >
                        Previous
                      </Button>
                      <Button
                        disabled={epageNumber >= enumPages}
                        onClick={enextPage}
                      >
                        Next
                      </Button>
                    </div>
                    <div
                      className="d-flex flex-row"
                      style={{
                        height: "700px",
                        overflow: "auto",
                        border: "1px solid black",
                      }}
                    >
                      <div>
                        <Document
                          file={data?.awsFileLocations?.esignDoc}
                          onLoadSuccess={onEDocumentLoadSuccess}
                        >
                          <Page pageNumber={epageNumber} />
                        </Document>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Tooltip
          title={
            Utils.isNullOrUndefined(data?.awsFileLocations?.esignDoc)
              ? "E-Sign Unavailable "
              : "Download E-Sign"
          }
        >
          <span>
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => {
                downloadFiles(
                  event,
                  data?.awsFileLocations?.esignDoc,
                  "eSign_" + data?.panDetails?.panId + ".pdf"
                );
              }}
              disabled={
                Utils.isNullOrUndefined(data?.awsFileLocations?.esignDoc)
                  ? true
                  : false
              }
              style={{ marginRight: "10px" }}
            >
              Download E-Sign Document
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            Utils.isNullOrUndefined(data?.awsFileLocations?.xmlFile)
              ? "XML Unavailable "
              : "Download XML File"
          }
        >
          <span>
            <Button
              variant="outlined"
              color="primary"
              onClick={(event) => {
                downloadFiles(
                  event,
                  data?.awsFileLocations?.xmlFile,
                  "xmlFile_" + data?.panDetails?.panId + ".xml"
                );
              }}
              disabled={
                Utils.isNullOrUndefined(data?.awsFileLocations?.xmlFile)
                  ? true
                  : false
              }
              style={{ marginRight: "10px" }}
            >
              Download .xml file
            </Button>
          </span>
        </Tooltip>
        <span>
          <Button
            onClick={() => {
              onReject();
            }}
            variant="outlined"
            color="error"
            disabled={
              data?.userDetails?.kycStatus === "REJECTED" ||
                data?.userDetails?.kycStatus === "APPROVED"
                ? true
                : false
            }
          >
            Reject
          </Button>
        </span>
        <span>
          <Button
            variant="contained"
            color="success"
            disabled={
              data?.userDetails?.kycStatus === "REJECTED" ||
                data?.userDetails?.kycStatus === "APPROVED"
                ? true
                : false
            }
            onClick={() => {
              onSubmit();
              close();
            }}
          >
            Accept
          </Button>
        </span>
      </DialogActions>
    </Dialog>
  );
};

export const profile_dialog = (data, PDFthumbnail, onSubmit, reload) => {
  useProfileDialogStore.setState({ data, PDFthumbnail, onSubmit, reload });
};
export { ProfileDialog };

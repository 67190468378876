import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { modification_dialog } from "../Components/Dialogs/ModificationDialog";
import Header from "../Components/Header";
export default function Requests() {
  const typeChange = (event) => {
    console.log(event.target.value);
  };
  const handleOpen = (key, event) => {
    console.log(key);
    modification_dialog("a", () => { });
    // let payLoad = {
    //   userid: key?.userid,
    // };
    // API.PostMethod("/kyc/getKycDetails", payLoad).then((response) => {
    //   profile_dialog(
    //     response?.data,
    //     () => {
    //       let payload = {
    //         approve: true,
    //         rejectedDoc: [],
    //         userid: response?.data?.userid,
    //       };
    //       API.PostMethod("/admin/docApproveOrReject", payload).then((res) => {
    //         let status_details = {
    //           ...Utils.STATUS_POPUP_DETAILS.success,
    //           message: "Profile has been accepted",
    //         };
    //         status_dialog(status_details, null, () => {
    //           setLoading(false);
    //           fetchData();
    //         });
    //       });
    //     },
    //     () => {
    //       setLoading(false);
    //       fetchData();
    //     }
    //   );
    // });
  };
  return (
    <div className="d-flex flex-column pkt-height-percentage-100 pkt-height-percentage-100 pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height pkt-padding-20">
        <div className="d-flex flex-row justify-content-between align-items-centers">
          <div style={{ fontWeight: 600, fontSize: "20px" }}>
            Modification Requests
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <FormControl size="small" className="pkt-margin-right-10">
              <InputLabel id="demo-select-small">Filter By</InputLabel>
              <Select
                className="pkt-height-40 pkt-width-250"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Filter By"
                onChange={typeChange}
                name="searchType"
              >
                <MenuItem value={"mobileNo"}>Mobile No. Change</MenuItem>
                <MenuItem value={"emailid"}>Email Change</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center pkt-width-percentage-100 pkt-height-percentage-100">
          <div
            style={{ width: "100%", overflow: "hidden" }}
            className="d-flex flex-column pkt-height-percentage-100 justify-content-between"
          >
            <TableContainer sx={{ height: "93% !important", width: "100%" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="right">Phone No.</TableCell>
                    <TableCell align="right">Request Date</TableCell>
                    <TableCell align="left">Modification Type</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center"> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <React.Fragment>
                    <TableRow>
                      <TableCell align="left">Ayush</TableCell>
                      <TableCell align="left">ayush@gmail.com</TableCell>
                      <TableCell align="right">9131688156</TableCell>
                      <TableCell align="right">15 July, 199</TableCell>
                      <TableCell align="left">Phone No. Change</TableCell>
                      <TableCell align="left">Pending</TableCell>
                      <TableCell align="center">
                        <div
                          // aria-controls={item}
                          onClick={(e) => {
                            handleOpen("a", e);
                          }}
                          className="pkt-cursor-pointer pkt-text-undeline"
                        >
                          View Request
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CollectionForm from "../Pages/Collection/CollectionForm";
import Collections from "../Pages/Collection/Collections";
import Dashboard from "../Pages/Dashboard";
import Login from "../Pages/Login";
import Members from "../Pages/Members";
import MyProfile from "../Pages/MyProfile";
import Pockets from "../Pages/Pockets/Pockets";
import PocketForm from "../Pages/Pockets/PocketForm";
import Requests from "../Pages/Requests";
import SubBrokers from "../Pages/SubBrokers";
import AgentGuard from "./guards/AgentGuard";
import AuthenticatedGuard from "./guards/AuthenticatedGuard";
import IsLoginGuard from "./guards/IsLoginGuard";
function Routing() {
  return (
    <React.Suspense>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" />} />

        {/* <Route element={<IsLoginGuard />}> */}
        <Route path="/login" element={<Login />} />
        {/* </Route> */}
        <Route element={<AuthenticatedGuard />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route element={<AgentGuard />}>
            <Route path="/members" element={<Members />} />
            <Route path="/subBroker" element={<SubBrokers />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/collection" element={<CollectionForm />} />
            <Route
              path="/collection/:collection_id"
              element={<CollectionForm />}
            />
            <Route path="/pockets" element={<Pockets />} />
            <Route path="/pocket" element={<PocketForm />} />
            <Route path="/pocket/:pocket_id" element={<PocketForm />} />
            <Route path="/requests" element={<Requests />}></Route>
          </Route>
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default Routing;

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from "@mui/material";
import { STATES_DISTRICTS } from "../CommonServices/StatesAndDistricts";

import React, { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { MdClear } from "react-icons/md";
import { RiFileCopyFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as API from "../CommonServices/Services";
import * as Utils from "../CommonServices/Utils";
import { addSubBroker_dialog } from "../Components/Dialogs/SubBrokerDialog";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
export default function SubBrokers() {
  const [data, setData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searched, setSearched] = useState("");
  const timeout = useRef();
  const [loading, setLoading] = useState(true);
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchType, setSearchType] = useState("name");
  const [message, setMessage] = useState(null);
  const [open, setOpen] = useState(false);
  const typeChange = (event) => {
    setSearchType(event.target.value);
  };
  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;
    setOpen(false);
  };
  const requestSearch = (e) => {
    setSearched(e.target.value);
    clearTimeout(timeout.current);
    if (e.target.value.length >= 3) {
      setSearchLoader(true);
      setLoading(true);
      timeout.current = setTimeout(() => {
        let s = e.target.value;
        let payLoad = {
          value: s,
          type: searchType,
        };
        API.PostMethod("/admin/getSubBrokersByRequest", payLoad).then(
          (response) => {
            setSearchLoader(false);
            setLoading(false);
            if (!Utils.isNullOrUndefined(response?.data?.brokers)) {
              setData(response?.data?.brokers);
            } else {
              setData([]);
            }
          }
        );
      }, 1000);
    }
  };
  const cancelSearch = () => {
    setSearched("");
    fetchData();
  };
  const addProfile = () => {
    addSubBroker_dialog(null, null, () => {
      fetchData();
    });
  };
  let navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    API.GetMethod("/admin/getAllSubBroker")
      .then((res) => {
        setData(res?.data?.brokers);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setData(null);
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editProfile = (key) => {
    let payLoad = {
      'value': key,
      'type': 'panno',
    };
    API.PostMethod("/admin/getSubBrokersByRequest", payLoad).then(
      (response) => {
        console.log(response?.data?.brokers[0])
        let distct = STATES_DISTRICTS.filter(
          (obj) => obj.state === response?.data?.brokers[0]?.state
        )[0]
        addSubBroker_dialog(response?.data?.brokers[0], distct, () => {
          fetchData();
        });
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="d-flex flex-column pkt-height-percentage-100 pkt-height-percentage-100 pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height pkt-padding-20">
        <div className="d-flex flex-row justify-content-between align-items-centers">
          <div style={{ fontWeight: 600, fontSize: "20px" }}>Sub-Brokers</div>
          <div className="d-flex r">
            <InputBase
              className="pkt-width-500"
              style={{
                background: "#F2EFFF",
                borderRadius: "34px",
                color: "#5655A8",
                padding: "0px 16px",
              }}
              onChange={requestSearch}
              placeholder="Search"
              value={searched}
              endAdornment={
                <InputAdornment position="end">
                  {searched.length === 0 ? (
                    <div></div>
                  ) : searchLoader ? (
                    <Box className="d-flex pkt-margin-right-10">
                      <CircularProgress size="1rem" />
                    </Box>
                  ) : (
                    <MdClear
                      className="pkt-height-23 pkt-width-23 pkt-cursor-pointer iconclr pkt-margin-right-10"
                      onClick={cancelSearch}
                    />
                  )}
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <IconButton className="pkt-margin-top-0">
                    <BiSearch className="pkt-height-20 pkt-width-20" />
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormControl size="small" className="pkt-margin-left-10">
              <InputLabel id="demo-select-small">Search By</InputLabel>
              <Select
                className="pkt-height-40 pkt-width-150"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Search By"
                onChange={typeChange}
                name="searchType"
                defaultValue={"name"}
              >
                <MenuItem value={"name"}>Name</MenuItem>
                <MenuItem value={"emailid"}>Email</MenuItem>
                <MenuItem value={"phoneno"}>Phone No.</MenuItem>
                <MenuItem value={"panno"}>PAN</MenuItem>
                <MenuItem value={"referralcode"}>Referral Code</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              addProfile();
            }}
            disabled={localStorage?.userType === "AGENT"}
          >
            Add Sub Broker
          </Button>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center pkt-width-percentage-100 pkt-height-percentage-100">
          {loading ? (
            <div className="pkt-height-percentage-100 pkt-width-percentage-100">
              <Loader />
            </div>
          ) : !Utils.isNullOrUndefined(data) ? (
            <div
              style={{ width: "100%", overflow: "hidden" }}
              className="d-flex flex-column pkt-height-percentage-100 justify-content-between"
            >
              <TableContainer sx={{ height: "93% !important", width: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Pan No.</TableCell>
                      <TableCell align="left">Phone No.</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Parent Ref Code</TableCell>
                      <TableCell align="left">Ref Code</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, key) => (
                        <React.Fragment key={item?.panNo}>
                          <TableRow>
                            <TableCell align="left">{item?.name}</TableCell>
                            <TableCell align="left">{item?.panNo}</TableCell>
                            <TableCell align="left">{item?.phoneNo}</TableCell>
                            <TableCell align="left">{item?.emailId}</TableCell>

                            <TableCell align="left">
                              {item?.parentReferralCode}
                              {item?.parentReferralCode ? (
                                <RiFileCopyFill
                                  className="pkt-width-16 pkt-height-16 pkt-margin-left-5 pkt-cursor-pointer"
                                  onClick={() => {
                                    Utils.copyToClipboard(item?.parentReferralCode);
                                    setMessage("Referral Code is copied !");
                                    setOpen(true);
                                  }}
                                  title="Copy to clipboard"
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="left">
                              {item?.referralCode}
                              {item?.referralCode ? (
                                <RiFileCopyFill
                                  className="pkt-width-16 pkt-height-16 pkt-margin-left-5 pkt-cursor-pointer"
                                  onClick={() => {
                                    Utils.copyToClipboard(item?.referralCode);
                                    setMessage("Referral Code is copied !");
                                    setOpen(true);
                                  }}
                                  title="Copy to clipboard"
                                />
                              ) : null}
                            </TableCell>
                            <TableCell align="center">
                              <div className="d-flex flex-row justify-content-center pkt-width-percentage-100">
                                <div
                                  aria-controls={item?.panNo}
                                  onClick={(e) => {
                                    editProfile(item?.panNo, e);
                                  }}
                                  className="pkt-cursor-pointer pkt-text-undeline"
                                >
                                  Edit Sub-broker
                                </div>

                              </div>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <div>No Members found!</div>
          )}
        </div>
        <Snackbar
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          open={open}
          autoHideDuration={5000}
          message={message}
          onClose={handleToClose}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleToClose}
              >
                <IoCloseCircleOutline
                  className="pkt-width-20 text-white pkt-height-20"
                  size="small"
                />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    </div>
  );
}

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { Form, Formik, useFormik } from "formik";
import fileDownload from "js-file-download";
import React, { useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import {
  MdAdd,
  MdArrowBack,
  MdClear,
  MdDone,
  MdOutlineDelete,
} from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import * as API from "../../CommonServices/Services";
import * as Utils from "../../CommonServices/Utils";
import { confirmation_dialog } from "../../Components/Dialogs/ConfirmationDialog";
import { status_dialog } from "../../Components/Dialogs/StatusDialog";
import Header from "../../Components/Header";
import Loader from "../../Components/Loader";
export default function CollectionForm() {
  const [searched, setSearched] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [existingList, setExistingList] = useState([]);
  const [searchList, setsearchList] = useState([]);
  const [isValidFileType, setValidFileType] = useState(true);
  const [fileLink, setFileLink] = useState(null);
  const [imgUploaded, setImgUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorFinal, setErrorFinal] = useState();
  const [errorMessage, setErrorMessage] = useState();
  let userName = localStorage.getItem("userName");
  let userType = localStorage.getItem("userType");
  let navigate = useNavigate();
  const timeout = useRef();
  const params = useParams();
  function backNavigate() {
    navigate("/collections");
  }

  function upLoad(e) {
    const files = e.target.files;
    const formData = new FormData();
    formData.append("img", files[0]);
  }

  function getBase64(file) {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }

  function handleFileInputChange(e, token) {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        const filterStockIndex = existingList.findIndex(
          (item) => item.token === token
        );
        existingList[filterStockIndex].stockImage = result.split(",")[1];
        setExistingList(existingList);
        collectionForm.setFieldValue("collectionMetaData", existingList);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleCollectionImage(e) {
    console.log(e.target.files[0]);
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        collectionForm.setFieldValue("collectionImage", result.split(",")[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // const onDrop = useCallback((acceptedFiles) => {
  //   if (!Utils.isNullOrUndefined(acceptedFiles) && acceptedFiles.length > 0) {
  //     acceptedFiles.map((file) => {
  //       console.log(file);
  //       const reader = new FileReader();
  //       reader.onload = function (e) {
  //         console.log(e);
  //         setFileLink(e.target.result);
  //       };
  //       setValidFileType(true);
  //       collectionForm.setFieldValue("image", file.name);
  //       setImgUploaded(true);
  //       reader.readAsDataURL(file);
  //       return file;
  //       // let formData = new FormData();
  //       // formData.append("image", file);
  //       // formData.append("candidate_id", params.id);
  //       // formData.append("interview_id", params.interview_id);
  //       // API.PostMethod("image/upload/interview", formData).then(
  //       //   (response) => {
  //       //     const reader = new FileReader();
  //       //     reader.onload = function (e) {
  //       //       setFileLink(e.target.result);
  //       //     };
  //       //     setValidFileType(true);
  //       //     collectionForm.setFieldValue(
  //       //       "image",
  //       //       {
  //       //         interview_profile_original: file.name,
  //       //         download_path: response.data,
  //       //       }
  //       //     );
  //       //     setImgUploaded(true);
  //       //     reader.readAsDataURL(file);
  //       //     return file;
  //       //   },
  //       //   (error) => {}
  //       // );
  //     });
  //   } else {
  //     setFileLink(null);
  //     setValidFileType(false);
  //   }
  // }, []);

  // const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
  //   useDropzone({
  //     accept: {
  //       document: [".jpeg", ".png", ".JPG", ".PNG"],
  //     },
  //     onDrop,
  //     maxFiles: 1,
  //     maxSize: 8388608,
  //   });

  const collectionForm = useFormik({
    initialValues: {
      collectionName: "",
      collectionLongDesc: "",
      collectionShortDesc: "",
      collectionExchange: "",
      collectionMetaData: [],
      collectionBackColor: "",
      collectionIllustration: "",
      collectionImage: "",
      collectionType: "",
    },
    validationSchema: Yup.object({
      collectionName: Yup.string().required("Collection Name is required!"),
      collectionLongDesc: Yup.string().required("Description is required!"),
      collectionShortDesc: Yup.string().required(
        "Short description is required!"
      ),
      collectionExchange: Yup.string().required(
        "Collection Exchange is required!"
      ),
      collectionMetaData: Yup.array(
        Yup.object({
          stockImage: Yup.string().required("Image is required!"),
          stockImageAttached: Yup.boolean("false"),
        })
      ),
      collectionBackColor: Yup.string().required("Color is required!"),
      collectionIllustration: Yup.string().required(
        "Illustration is required!"
      ),
      collectionImage: Yup.string().required("Image is required!"),
      collectionType: Yup.string().required("Type is required!"),
    }),
  });

  let es = Yup.object({
    collectionName: Yup.string().required("Collection Name is required!"),
    collectionLongDesc: Yup.string().required("Description is required!"),
    collectionShortDesc: Yup.string().required(
      "Short description is required!"
    ),
    collectionExchange: Yup.string().required(
      "Collection Exchange is required!"
    ),
    collectionMetaData: Yup.array(
      Yup.object({
        stockImage: Yup.string().required("Image is required!"),
      })
    ).min(1, "Please add atleast 1 stock!"),
    collectionBackColor: Yup.string().required("Color is required!"),
    collectionIllustration: Yup.string().required("Illustration is required!"),
    collectionImage: Yup.string().required("Image is required!"),
    collectionType: Yup.string().required("Type is required!"),
  });

  const handleSubmit = (values) => {
    console.log(values);
    es.validate(values)
      .then(() => {
        setErrorFinal(null);
        if (values?.collectionMetaData.length !== 0 && !params?.collection_id) {
          let collectionData = values?.collectionMetaData;
          for (let i = 0; i < collectionData.length; i++) {
            collectionData[i].displayName = collectionData[i]?.displayName;
            collectionData[i].isTradable = collectionData[i]?.isTradable;
            collectionData[i].tradingSymbol =
              values?.collectionMetaData[i]?.tradingSymbol;
            collectionData[i].stockId = values?.collectionMetaData[i]?.token;
            if (Utils.isNullOrUndefined(collectionData[i].stockImage)) {
              collectionData[i].stockImage = "";
            }
          }
        }

        for (let i = 0; i < values?.collectionMetaData.length; i++) {
          if (values?.collectionMetaData[i]?.stockImage.startsWith("https:")) {
            values.collectionMetaData[i].stockImageAttached = false;
          } else {
            values.collectionMetaData[i].stockImageAttached = true;
          }
        }
        if (values?.collectionImage.startsWith("https:")) {
          values.collectionImageAttached = false;
        } else {
          values.collectionImageAttached = true;
        }
        let payLoad = values;
        if (params?.collection_id) {
          payLoad.collectionId = params?.collection_id;
          confirmation_dialog(
            "Are you sure you want to update collection?",
            () => {
              API.PostMethod("/rbac/modifyCollections", payLoad).then(
                (response) => {
                  let status_details = {
                    ...Utils.STATUS_POPUP_DETAILS.success,
                    message: "Collection has been updated!",
                    title: "Success",
                  };
                  status_dialog(status_details, null, () => {
                    navigate("/collections");
                  });
                }
              );
            }
          );
        } else {
          confirmation_dialog(
            "Are you sure you want to create collection?",
            () => {
              API.PostMethod("/rbac/createCollections", payLoad).then(
                (response) => {
                  let status_details = {
                    ...Utils.STATUS_POPUP_DETAILS.success,
                    message: "Collection has been created!",
                    title: "Success",
                  };
                  status_dialog(status_details, null, () => {
                    navigate("/collections");
                  });
                }
              );
            }
          );
        }
      })
      .catch((err) => {
        console.log(err.errors[0]);
        setErrorFinal(err.errors[0]);
      });
  };
  const handleChange = (e) => {
    let search = e.target.value.toLowerCase();
    setSearchLoader(true);
    setSearched(e.target.value);
    timeout.current = setTimeout(() => {
      API.PostMethod("/contractdetails/searchScrip", { key: e.target.value.toLowerCase() })
        .then((res) => {
          setsearchList(res.data);
          setSearchLoader(false);
        });
    }, 1000);
  };
  const cancelSearch = () => {
    setSearched("");
    setsearchList([]);
  };
  const addToList = (token) => {
    const newList = existingList.concat(
      searchList.filter((item) => item.token === token)
    );

    setExistingList(newList);
    setSearched("");
    collectionForm.setFieldValue("collectionMetaData", newList);
  };

  const deleteItem = (token) => {
    const newList = existingList.filter((item) => item.token !== token);
    setExistingList(newList);
    collectionForm.setFieldValue("collectionMetaData", newList);
  };

  const deleteCollection = () => {
    let payLoad = { collectionId: params?.collection_id };
    confirmation_dialog("Are you sure you want to delete collection?", () => {
      API.PostMethod("/rbac/deleteCollections", payLoad).then((response) => {
        let status_details = {
          ...Utils.STATUS_POPUP_DETAILS.success,
          message: "Collection has been deleted!",
          title: "Success",
        };
        status_dialog(status_details, null, () => {
          navigate("/collections");
        });
      });
    });
  };

  function handleNameChange(event) {
    collectionForm.setFieldValue("collectionName", event.target.value);
  }
  function handleExchangeChange(event) {
    collectionForm.setFieldValue("collectionExchange", event.target.value);
  }
  function handleDescriptionChange(event) {
    collectionForm.setFieldValue("collectionLongDesc", event.target.value);
  }

  function handleColorChange(event) {
    collectionForm.setFieldValue("collectionBackColor", event.target.value);
  }
  function handleIllustration(event) {
    collectionForm.setFieldValue("collectionIllustration", event.target.value);
  }
  function handleType(event) {
    collectionForm.setFieldValue("collectionType", event.target.value);
  }
  function handleShortDescriptionChange(event) {
    collectionForm.setFieldValue("collectionShortDesc", event.target.value);
  }
  const fetchData = async () => {
    setLoading(true);
    let payLoad = { collectionId: params?.collection_id };
    API.PostMethod("/rbac/fetchCollections", payLoad)
      .then((res) => {
        setFormValues(res);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        setErrorMessage(error?.response?.data?.message);
      });
  };
  const setFormValues = (data) => {
    collectionForm.setValues(data?.data);
    setExistingList(data?.data?.collectionMetaData);
  };

  const downloadFiles = (event, data, name) => {
    event.stopPropagation();
    API.DownloadFile(data).then((response) => {
      fileDownload(response, name);
    });
  };
  useEffect(() => {
    if (params?.collection_id) {
      fetchData();
    }
  }, []);
  return (
    <div className="d-flex flex-column pkt-height-percentage-100 pkt-height-percentage-100 pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height ">
        <div className="d-flex flex-row justify-content-between align-items-center pkt-padding-left-20 pkt-padding-right-20 pkt-padding-top-20 pkt-padding-bottom-10">
          <div className="d-flex flex-row align-items-center">
            <MdArrowBack
              className="pkt-width-20 pkt-height-20 pkt-cursor-pointer  pkt-margin-right-10"
              onClick={() => backNavigate()}
            />{" "}
            <div style={{ fontWeight: 600, fontSize: "20px" }}>
              {params?.collection_id
                ? "View/Edit Collection"
                : "Add Collection"}
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            {errorFinal ? (
              <span className="pkt-error pkt-font-size-18 pkt-margin-left-10">
                {errorFinal}
              </span>
            ) : null}
            {params?.collection_id ? (
              <Button
                variant="outlined"
                className="pkt-margin-right-15"
                color="error"
                onClick={() => {
                  deleteCollection();
                }}
                type="delete"
                disabled={Utils.isNullOrUndefined(errorMessage) ? false : true}
              >
                Delete Collection
              </Button>
            ) : null}
            <Button
              variant="contained"
              onClick={() => {
                handleSubmit(collectionForm.values);
              }}
              type="submit"
              disabled={Utils.isNullOrUndefined(errorMessage) ? false : true}
            >
              {" "}
              {params?.collection_id
                ? "Update Collection"
                : "Create Collection"}
            </Button>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center pkt-width-percentage-100 pkt-height-percentage-100">
          {loading ? (
            <div className="pkt-height-percentage-100 pkt-width-percentage-100">
              <Loader />
            </div>
          ) : Utils.isNullOrUndefined(errorMessage) ? (
            <div
              className="d-flex flex-row pkt-height-percentage-100 pkt-width-percentage-100 pkt-padding-10"
              style={{ background: "#e1e2e4" }}
            >
              <div className="d-flex pkt-padding-10 col-8">
                <div
                  className="d-flex flex-column col-12  align-items-start justify-content-start pkt-padding-15"
                  style={{ background: "white", borderRadius: "4px" }}
                >
                  <Formik onSubmit={collectionForm.onSubmit}>
                    <Form className="d-flex flex-column col-12">
                      <div className="d-flex col-12">
                        <div className="d-flex col-2 pkt-font-bold">
                          Collection Name:
                        </div>
                        <FormControl className="d-flex col-10">
                          <TextField
                            className="w-100"
                            id="outlined-size-small"
                            size="small"
                            name="collectionName"
                            onChange={handleNameChange}
                            placeholder="Collection Name"
                            value={collectionForm.values.collectionName}
                            error={
                              collectionForm.touched.collectionName &&
                                collectionForm.errors.collectionName
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          />
                          <div>
                            {collectionForm.touched.collectionName &&
                              collectionForm.errors.collectionName ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionName}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>{" "}
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Collection Exchange:
                        </div>
                        <FormControl size="small" className="d-flex col-10">
                          <InputLabel id="demo-select-small">
                            {" "}
                            Exchange
                          </InputLabel>
                          <Select
                            className="pkt-height-40"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={collectionForm.values.collectionExchange}
                            label="Exchange"
                            onChange={handleExchangeChange}
                            name="collectionExchange"
                            error={
                              collectionForm.touched.collectionExchange &&
                                collectionForm.errors.collectionExchange
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          >
                            <MenuItem value="NSE">NSE</MenuItem>{" "}
                            <MenuItem value="BSE">BSE</MenuItem>{" "}
                            <MenuItem value="FNO">FNO</MenuItem>
                            <MenuItem value="MCX">MCX</MenuItem>
                          </Select>
                          <div>
                            {collectionForm.touched.collectionExchange &&
                              collectionForm.errors.collectionExchange ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionExchange}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Short Description:
                        </div>
                        <FormControl className="d-flex col-10">
                          <TextField
                            className="w-100"
                            id="outlined-size-small"
                            size="small"
                            name="collectionShortDesc"
                            onChange={handleShortDescriptionChange}
                            placeholder="Description"
                            multiline
                            rows={3}
                            value={collectionForm.values.collectionShortDesc}
                            error={
                              collectionForm.touched.collectionShortDesc &&
                                collectionForm.errors.collectionShortDesc
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          />
                          <div>
                            {collectionForm.touched.collectionShortDesc &&
                              collectionForm.errors.collectionShortDesc ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionShortDesc}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Description:
                        </div>
                        <FormControl className="d-flex col-10">
                          <TextField
                            className="w-100"
                            id="outlined-size-small"
                            size="small"
                            name="collectionLongDesc"
                            onChange={handleDescriptionChange}
                            placeholder="Description"
                            multiline
                            rows={6}
                            value={collectionForm.values.collectionLongDesc}
                            error={
                              collectionForm.touched.collectionLongDesc &&
                                collectionForm.errors.collectionLongDesc
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          />
                          <div>
                            {collectionForm.touched.collectionLongDesc &&
                              collectionForm.errors.collectionLongDesc ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionLongDesc}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Background Color:
                        </div>
                        <FormControl className="d-flex col-10">
                          <TextField
                            className="w-100"
                            id="outlined-size-small"
                            size="small"
                            name="collectionBackColor"
                            onChange={handleColorChange}
                            placeholder="Background Color"
                            multiline
                            value={collectionForm.values.collectionBackColor}
                            error={
                              collectionForm.touched.collectionBackColor &&
                                collectionForm.errors.collectionBackColor
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          />
                          <div>
                            {collectionForm.touched.collectionBackColor &&
                              collectionForm.errors.collectionBackColor ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionBackColor}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>{" "}
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Illustration:
                        </div>
                        <FormControl className="d-flex col-10">
                          <TextField
                            className="w-100"
                            id="outlined-size-small"
                            size="small"
                            name="collectionIllustration"
                            onChange={handleIllustration}
                            placeholder="Illustration"
                            multiline
                            value={collectionForm.values.collectionIllustration}
                            error={
                              collectionForm.touched.collectionIllustration &&
                                collectionForm.errors.collectionIllustration
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          />
                          <div>
                            {collectionForm.touched.collectionIllustration &&
                              collectionForm.errors.collectionIllustration ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionIllustration}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>{" "}
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">Type:</div>
                        <FormControl size="small" className="d-flex col-10">
                          <InputLabel id="demo-select-small"> Type</InputLabel>
                          <Select
                            className="pkt-height-40"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={collectionForm.values.collectionType}
                            label="Type"
                            onChange={handleType}
                            name="collectionType"
                            error={
                              collectionForm.touched.collectionType &&
                                collectionForm.errors.collectionType
                                ? true
                                : false
                            }
                            onBlur={collectionForm.handleBlur}
                          >
                            <MenuItem value="Basic">Basic</MenuItem>{" "}
                            <MenuItem value="Pro">Pro</MenuItem>{" "}
                          </Select>
                          <div>
                            {collectionForm.touched.collectionType &&
                              collectionForm.errors.collectionType ? (
                              <span className="pkt-error">
                                {collectionForm.errors.collectionType}
                              </span>
                            ) : null}
                          </div>
                        </FormControl>
                      </div>
                      <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Collection Image:
                        </div>
                        <FormControl className="d-flex col-10">
                          {params?.collection_id ? (
                            <React.Fragment>
                              <input
                                type="file"
                                name="img"
                                accept="image/png"
                                onChange={(e) => {
                                  handleCollectionImage(e);
                                }}
                              ></input>
                              <span
                                className="pkt-cursor-pointer pkt-text-underline pkt-margin-right-20"
                                onClick={(event) => {
                                  downloadFiles(
                                    event,
                                    collectionForm.values.collectionImage,
                                    collectionForm.values.collectionName +
                                    ".png"
                                  );
                                }}
                              >
                                {" "}
                                Download Image
                              </span>
                            </React.Fragment>
                          ) : (
                            <input
                              type="file"
                              name="img"
                              accept="image/png"
                              onChange={(e) => {
                                handleCollectionImage(e);
                              }}
                            ></input>
                          )}
                        </FormControl>
                      </div>
                      {/* <div className="d-flex col-12 pkt-margin-top-10">
                        <div className="d-flex col-2 pkt-font-bold">
                          Collection Image:
                        </div>
                        <Card className="d-flex flex-column secondary flex-grow-1 pkt-margin-bottom-12 pkt-margin-bottom-0 pkt-padding-12 pkt-margin-0">
                          <div className="d-flex flex-column ">
                            <div
                              onDrop={onDrop}
                              className="d-flex pkt-bg-white flex-wrap justify-content-center upload-field flex-grow-1 pkt-margin-bottom-10"
                            >
                              {!Utils.isNullOrUndefined(
                                collectionForm.values?.image
                              ) ? (
                                <div
                                  className="d-flex flex-column pkt-color2 justify-content-center align-items-center col-md-4 col-lg-3 col-sm-12 col-xs-12"
                                  // href={fileLink}
                                  target="_blank"
                                >
                                  <img
                                    src={fileLink}
                                    alt={collectionForm.values.image}
                                    className="pkt-cursor-pointer"
                                    width="100"
                                    height="80"
                                  />
                                  {collectionForm.values.image}
                                </div>
                              ) : null}
                              <div
                                {...getRootProps({ className: "dropzone" })}
                                className="d-flex flex-grow-1 pkt-padding-10 pkt-cursor-pointer"
                              >
                                <input
                                  name="upload_file"
                                  className="input-zone"
                                  {...getInputProps()}
                                />
                                <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                                  <MdCloudUpload className="pkt-width-30 pkt-height-30 pkt-margin-bottom-10 pkt-cursor-pointer" />
                                  {isDragActive ? (
                                    <div className="">
                                      Release to drop the files here
                                    </div>
                                  ) : (
                                    <div className="">
                                      Drag and drop or{" "}
                                      <a className="pkt-color2" href="">
                                        browse
                                      </a>
                                      {!Utils.isNullOrUndefined(
                                        collectionForm.values?.image
                                      ) && acceptedFiles.length > 0
                                        ? " to change the image"
                                        : " collection image"}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="pkt-margin-left-10">
                              {!isValidFileType ? (
                                <ul className="pkt-error pkt-margin-bottom-10">
                                  <li>
                                    Please upload only in .PNG or .JPG or .JPEG
                                    format.
                                  </li>
                                  <li>Maximum number of Image allowed is 1</li>
                                </ul>
                              ) : collectionForm.touched.image &&
                                collectionForm.errors.image ? (
                                <span className="pkt-error pkt-margin-bottom-10">
                                  {collectionForm.errors.image}
                                </span>
                              ) : null}
                            </div>
                            <div>
                              Note : Please upload only in .PNG or .JPG or .JPEG
                              format.(size should be less than 8MB)
                            </div>
                          </div>
                        </Card>
                      </div> */}
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="d-flex pkt-padding-10 col-4">
                <div
                  className="d-flex flex-column col-12"
                  style={{ background: "white", borderRadius: "4px" }}
                >
                  {" "}
                  <FormControl>
                    <TextField
                      sx={{ padding: "10px" }}
                      size="small"
                      variant="standard"
                      onChange={handleChange}
                      placeholder="Search Symbol"
                      value={searched}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <BiSearch className="pkt-height-20 pkt-width-20 " />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {searched.length === 0 ? (
                              <div></div>
                            ) : searchLoader ? (
                              <Box className="d-flex pkt-margin-right-10">
                                <CircularProgress size="1rem" />
                              </Box>
                            ) : (
                              <MdClear
                                className="pkt-height-20 pkt-width-20 pkt-cursor-pointer  pkt-margin-right-10"
                                onClick={cancelSearch}
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <hr className="pkt-margin-top-0 pkt-divider pkt-margin-bottom-1"></hr>
                  <div className="pkt-overflow-y-auto  pkt-max-height-percentage-100">
                    {Utils.isNullOrUndefined(searched) ? null : (
                      <div className="d-flex flex-column">
                        {searchList.map((item) => (
                          <div style={{ lineHeight: 2 }} key={item?.token}>
                            <div className="d-flex pkt-padding-left-10 pkt-padding-right-10 flex-row justify-content-between align-items-center">
                              <div className="d-flex flex-column">
                                <div>{item?.symbol}</div>
                              </div>
                              <div className="d-flex flex-row align-items-center">
                                {existingList.some(
                                  (a) => a.token === item.token
                                ) ? (
                                  <MdDone
                                    className="pkt-height-23 pkt-width-23 pkt-cursor-pointer"
                                    title="Already added"
                                  />
                                ) : (
                                  <MdAdd
                                    className="pkt-height-23 pkt-width-23 pkt-cursor-pointer"
                                    title="Add to list"
                                    onClick={() => {
                                      addToList(item.token);
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            <hr className="pkt-margin-top-0 pkt-divider pkt-margin-bottom-1" />
                          </div>
                        ))}
                      </div>
                    )}
                    {Utils.isNullOrUndefined(existingList) ? (
                      <div className="d-flex justify-content-center align-items-center">
                        No Stocks Found!
                      </div>
                    ) : (
                      existingList.map((item) => (
                        <div style={{ lineHeight: 4 }} key={item?.token}>
                          <div className="d-flex pkt-padding-left-10 pkt-padding-right-10 flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                              <div className="d-flex flex-row align-items-baseline">
                                <div>{item?.symbol}</div>
                                <div className="pkt-font-size-10 pkt-margin-left-8 pkt-font-weight-300">
                                  {item?.exchange}
                                </div>
                              </div>
                              <div>{item?.name}</div>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                              {params?.collection_id ? (
                                <React.Fragment>
                                  <input
                                    style={{ lineHeight: 2 }}
                                    type="file"
                                    name="img"
                                    accept="image/png"
                                    onChange={(e) => {
                                      handleFileInputChange(e, item.token);
                                    }}
                                  ></input>
                                  <span
                                    className="pkt-cursor-pointer pkt-text-underline pkt-margin-right-20"
                                    onClick={(event) => {
                                      downloadFiles(
                                        event,
                                        item?.stockImage,
                                        item?.symbol + ".png"
                                      );
                                    }}
                                  >
                                    {" "}
                                    Download Image
                                  </span>
                                </React.Fragment>
                              ) : (
                                <input
                                  style={{ lineHeight: 2 }}
                                  type="file"
                                  name="img"
                                  accept="image/png"
                                  onChange={(e) => {
                                    handleFileInputChange(e, item.token);
                                  }}
                                ></input>
                              )}

                              <MdOutlineDelete
                                className="pkt-height-23 pkt-width-23 pkt-cursor-pointer"
                                title="Delete"
                                onClick={() => {
                                  deleteItem(item.token);
                                }}
                              />
                            </div>
                          </div>
                          <hr className="pkt-margin-top-0 pkt-divider pkt-margin-bottom-1" />
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>{errorMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
}

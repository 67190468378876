import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Loader() {
  return (
    <Box
      sx={{ display: "flex" }}
      className="pkt-height-percentage-100 pkt-width-percentage-100 justify-content-center align-items-center flex-column"
    >
      <CircularProgress />
      <span className="pkt-margin-top-10">Loading...</span>
    </Box>
  );
}

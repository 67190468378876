import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AgentGuard = () => {
  const userType = localStorage.getItem("userType");

  const [location, setLocation] = useState(useLocation());

  useEffect(() => {
    userType === "SUPERADMIN" ? <Outlet /> : <Navigate to="/dashboard" />;
  }, [location]);

  return userType === "SUPERADMIN" ? <Outlet /> : <Navigate to="/dashboard" />;
};

export default AgentGuard;

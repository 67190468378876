import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Menu,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import generatePdfThumbnails from "pdf-thumbnails-generator";
import React, { useContext, useEffect, useRef, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { MdClear } from "react-icons/md";
import LoadingOverlay from "react-loading-overlay";
import { useNavigate } from "react-router-dom";
import sorter from "sort-nested-json";
import styled, { css } from "styled-components";
import { Context } from "../CommonServices/Context";
import * as API from "../CommonServices/Services";
import * as Utils from "../CommonServices/Utils";
import { profile_dialog } from "../Components/Dialogs/ProfileDialog";
import { status_dialog } from "../Components/Dialogs/StatusDialog";
import Header from "../Components/Header";
import Loader from "../Components/Loader";

const DarkBackground = styled.div`
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.disappear &&
    css`
      display: flex; /* show */
    `}
`;
function Dashboard() {
  const [data, setData] = useState([]);
  let navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [listData, setListData] = useState(null);
  const open = Boolean(anchorEl);
  const opernSearch = Boolean(anchorE2);
  const [searched, setSearched] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchType, setSearchType] = useState("panname");
  const [context, setContext] = useContext(Context);
  let userType = localStorage.getItem("userType");
  const [filterBy, setFilterBy] = useState("esigned");
  const [order, setOrder] = useState("asc");
  let sortDataDetails = ["created_date", "asc"];
  const [overLayloader, setOverLayLoader] = useState(false);

  async function generateThumbnails(data) {
    try {
      const thumbnails = await generatePdfThumbnails(data, 100);
      return thumbnails[0]?.thumbnail;
    } catch (err) { }
  }
  async function generateAadharThumbnails(data) {
    try {
      const thumbnails = await generatePdfThumbnails(data, 100);
      return thumbnails[0]?.thumbnail;
    } catch (err) { }
  }
  async function generatePanThumbnails(data) {
    try {
      const thumbnails = await generatePdfThumbnails(data, 100);
      return thumbnails[0]?.thumbnail;
    } catch (err) { }
  }
  async function generateeThumbnails(data) {
    try {
      const thumbnails = await generatePdfThumbnails(data, 100);
      return thumbnails[0]?.thumbnail;
    } catch (err) { }
  }
  const handleSort = (columnName) => {
    if (columnName === "modifed_on")
      if (order === "asc") {
        let sorteddddd = sorter
          .sort(filteredData)
          .asc("userDetails.adminModifiedAt");
        setFilteredData(sorteddddd);
        setOrder("desc");
      } else {
        let sorteddddd = sorter
          .sort(filteredData)
          .desc("userDetails.adminModifiedAt");
        setFilteredData(sorteddddd);
        setOrder("asc");
      }
    else {
      if (order === "asc") {
        let sorteddddd = sorter
          .sort(filteredData)
          .asc("userDetails.kycStartTime");
        setFilteredData(sorteddddd);
        setOrder("desc");
      } else {
        let sorteddddd = sorter
          .sort(filteredData)
          .desc("userDetails.kycStartTime");
        setFilteredData(sorteddddd);
        setOrder("asc");
      }
    }
  };
  const handleSearchClose = () => {
    setAnchorE2(null);
    setListData(null);
  };

  const timeout = useRef();

  const typeChange = (event) => {
    setSearchType(event.target.value);
  };
  const requestSearch = (e) => {
    setSearched(e.target.value);
    clearTimeout(timeout.current);
    if (e.target.value.length >= 3) {
      setSearchLoader(true);
      timeout.current = setTimeout(() => {
        let s;
        if (searchType === "panname" || searchType === "panid") {
          s = e.target.value.toUpperCase();
        } else {
          s = e.target.value;
        }
        let payLoad = {
          searchBy: s,
          type: searchType,
        };
        API.PostMethod("/rbac/searchForCustomer", payLoad).then((response) => {
          setAnchorE2(e.target);
          setSearchLoader(false);
          if (!Utils.isNullOrUndefined(response?.data)) {
            setListData(response?.data);
          } else {
            setListData([]);
          }
        });
      }, 1000);
    }
  };
  const cancelSearch = () => {
    setSearched("");
    setListData([]);
  };
  const handleMenuItemClose = (key, e) => {
    setAnchorE2(null);
    let payLoad = { userid: listData?.[key].userid };
    API.PostMethod("/admin/getKycDetails", payLoad).then((response) => {
      setOverLayLoader(true);
      cancelSearch();
      Promise.all([
        generateThumbnails(response?.data?.awsFileLocations?.bankStatement),
        generatePanThumbnails(response?.data?.panDetails?.PanFrontLocation),
        generateAadharThumbnails(
          response?.data?.addressProofDetails?.frontLocation
        ),

        generateeThumbnails(response?.data?.awsFileLocations?.esignDoc),
      ]).then((value) => {
        setOverLayLoader(false);
        profile_dialog(
          response?.data,
          {
            aadhar: value[2],
            pan: value[1],
            bankStatement: value[0],
            esignThumbnail: value[3],
          },
          () => {
            let payload = {
              approve: true,
              rejectedDoc: [],
              userid: response?.data?.userid,
            };
            API.PostMethod("/admin/docApproveOrReject", payload).then((res) => {
              let status_details = {
                ...Utils.STATUS_POPUP_DETAILS.success,
                message: "Profile has been accepted",
              };
              status_dialog(status_details, null, () => {
                setLoading(false);
                fetchData(filterBy);
              });
            });
          },
          () => {
            setLoading(false);
            fetchData(filterBy);
          }
        );
      });
    });
  };

  const fetchData = (filterType) => {
    setLoading(true);
    API.GetMethod("/admin/getAllKycDetails")
      .then((response) => {
        setLoading(false);
        setData(response?.data?.allKycDetails);
        setFilteredData(response?.data?.allKycDetails);
        let x = response?.data?.allKycDetails.filter(
          (eachObj) =>
            eachObj.userDetails.kycStatus.toLowerCase() ===
            filterType.toLowerCase()
        );
        let y = filterType == "all" ? sorter.sort(response?.data?.allKycDetails).desc("userDetails.kycStartTime") : sorter.sort(x).desc("userDetails.kycStartTime");
        setPage(0);
        setFilteredData(
          y
        );
      })
      .catch((err) => {
        setLoading(false);
        setData(null);
        setFilteredData(null);
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };

  const handleChangeType = (event) => {
    fetchData(event.target.value);
    setFilterBy(event.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (key, event) => {
    let payLoad = {
      userid: key?.userid,
    };
    API.PostMethod("/admin/getKycDetails", payLoad).then((response) => {
      setOverLayLoader(true);
      Promise.all([
        generateThumbnails(response?.data?.awsFileLocations?.bankStatement),
        generatePanThumbnails(response?.data?.panDetails?.PanFrontLocation),
        generateAadharThumbnails(
          response?.data?.addressProofDetails?.frontLocation
        ),
        generateeThumbnails(response?.data?.awsFileLocations?.esignDoc),
      ]).then((value) => {
        setOverLayLoader(false);
        profile_dialog(
          response?.data,
          {
            aadhar: value[2],
            pan: value[1],
            bankStatement: value[0],
            esignThumbnail: value[3],
          },

          () => {
            let payload = {
              approve: true,
              rejectedDoc: [],
              userid: response?.data?.userid,
            };
            API.PostMethod("/admin/docApproveOrReject", payload).then((res) => {
              let status_details = {
                ...Utils.STATUS_POPUP_DETAILS.success,
                message: "Profile has been accepted",
              };
              status_dialog(status_details, null, () => {
                setLoading(false);
                fetchData(filterBy);
              });
            });
          },
          () => {
            setLoading(false);
            fetchData(filterBy);
          }
        );
      });
    });
  };

  useEffect(() => {
    fetchData("esigned");
  }, []);

  return (
    <div className="d-flex flex-column pkt-height-percentage-100 pkt-height-percentage-100 pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height pkt-padding-30">
        <DarkBackground disappear={overLayloader}>
          <LoadingOverlay
            active={true}
            spinner
            text="Loading Profile..."
            styles={{
              spinner: (base) => ({
                ...base,
                width: "150px",
                "& svg circle": {
                  stroke: "grey",
                },
              }),
            }}
          ></LoadingOverlay>
        </DarkBackground>{" "}
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div style={{ fontWeight: 600, fontSize: "20px" }}>KYC Requests</div>
          <div className="d-flex col-11 justify-content-center">
            <InputBase
              className="pkt-width-500"
              style={{
                border: "1px solid grey",
                borderRadius: "34px",
                color: "#5655A8",
                padding: "0px 16px",
              }}
              onChange={requestSearch}
              placeholder="Search"
              value={searched}
              endAdornment={
                <InputAdornment position="end">
                  {searched.length === 0 ? (
                    <div></div>
                  ) : searchLoader ? (
                    <Box className="d-flex pkt-margin-right-10">
                      <CircularProgress size="1rem" />
                    </Box>
                  ) : (
                    <MdClear
                      className="pkt-height-23 pkt-width-23 pkt-cursor-pointer iconclr pkt-margin-right-10"
                      onClick={cancelSearch}
                    />
                  )}
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <IconButton className="pkt-margin-top-0">
                    <BiSearch className="pkt-height-20 pkt-width-20" />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorE2}
              open={opernSearch}
              onClose={handleSearchClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <MenuList
                className="pkt-width-500"
                style={{
                  maxHeight: "200px",
                }}
              >
                {Utils.isNullOrUndefined(listData) ? (
                  <MenuItem>No Profile found!</MenuItem>
                ) : (
                  listData.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        aria-controls={index}
                        onClick={(e) => {
                          handleMenuItemClose(index, e);
                        }}
                      >
                        <div className="d-flex flex-column pkt-width-percentage-100">
                          <div className="d-flex flex-row justify-content-between">
                            <div>{item?.panName}</div>
                            <div
                              className="pkt-font-italic"
                              style={{
                                fontSize: "14px",
                                color:
                                  item?.kycStatus === "APPROVED"
                                    ? "#40b508"
                                    : "red",
                              }}
                            >
                              {item?.kycStatus}
                            </div>
                          </div>
                          <div
                            className="d-flex flex-row pkt-margin-left-5"
                            style={{ fontSize: "12px", color: "gray" }}
                          >
                            <span>{item?.emailId} |</span>
                            <span className="pkt-margin-left-5">
                              {" "}
                              {item?.mobileNumber} |
                            </span>
                            <span className="pkt-margin-left-5">
                              {" "}
                              {item?.panNumber}
                            </span>
                          </div>
                        </div>
                      </MenuItem>
                    );
                  })
                )}
              </MenuList>
            </Menu>
            <FormControl size="small" className="pkt-margin-left-10">
              <InputLabel id="demo-select-small">Search By</InputLabel>
              <Select
                className="pkt-height-40 pkt-width-150"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Search By"
                onChange={typeChange}
                name="searchType"
                defaultValue={"panname"}
              >
                <MenuItem value={"panname"}>Name</MenuItem>
                <MenuItem value={"emailid"}>Email</MenuItem>
                <MenuItem value={"mobileno"}>Phone No.</MenuItem>
                <MenuItem value={"panid"}>PAN</MenuItem>
              </Select>
            </FormControl>
            <div className="d-flex flex-row align-items-center pkt-margin-left-20">
              <span style={{ textAlign: "center" }}>Filter By:</span>
              <FormControl className="pkt-margin-left-20">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  defaultValue="all"
                  value={filterBy}
                  onChange={handleChangeType}
                >
                  {" "}
                  <FormControlLabel
                    value="esigned"
                    control={<Radio />}
                    label="E-Signed"
                  />
                  <FormControlLabel
                    value="pending"
                    control={<Radio />}
                    label="Pending"
                  />
                  <FormControlLabel
                    value="approved"
                    control={<Radio />}
                    label="Approved"
                  />
                  <FormControlLabel
                    value="rejected"
                    control={<Radio />}
                    label="Rejected"
                  />
                  <FormControlLabel
                    value="all"
                    control={<Radio />}
                    label="All"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="pkt-width-percentage-100">
            {" "}
            <Loader />
          </div>
        ) : (
          <div
            style={{ width: "100%", overflow: "hidden", justifyContent: "space-between", flex: 1 }}
            className="d-flex flex-column"
          >
            <TableContainer
              sx={{
                height: "90% !important",
                width: "100%",
                maxHeight: "90% !important",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("created_date");
                        }}
                      >
                        Created on
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        direction={order}
                        onClick={() => {
                          handleSort("modifed_on");
                        }}
                      >
                        Last Modified on
                      </TableSortLabel>
                    </TableCell>
                    {/* <TableCell align="left">Request Type</TableCell> */}
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Utils.isNullOrUndefined(filteredData) ? (
                    <span> No data to display</span>
                  ) : (
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, key) => (
                        <React.Fragment key={item?.userid}>
                          <TableRow>
                            <TableCell align="left">
                              {Utils.isNullOrUndefined(
                                item?.userDetails?.name
                              )
                                ? "-"
                                : item?.userDetails?.name}
                            </TableCell>
                            <TableCell align="left">
                              {Utils.isNullOrUndefined(
                                item?.userDetails?.emailId
                              )
                                ? "-"
                                : item?.userDetails?.emailId}
                            </TableCell>
                            <TableCell align="right">
                              {Utils.isNullOrUndefined(
                                item?.userDetails?.kycStartTime
                              ) ? (
                                "-"
                              ) : (
                                <span>
                                  {" "}
                                  {Utils.DateTimeFormat(
                                    item?.userDetails?.kycStartTime.slice(
                                      0,
                                      item?.userDetails?.kycStartTime.indexOf(
                                        "UTC"
                                      ) + 2
                                    )
                                  )}
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {Utils.isNullOrUndefined(
                                item?.userDetails?.adminModifiedAt
                              ) ? (
                                "-"
                              ) : (
                                <span>
                                  {" "}
                                  {Utils.DateTimeFormat(
                                    item?.userDetails?.adminModifiedAt.slice(
                                      0,
                                      item?.userDetails?.adminModifiedAt.indexOf(
                                        "UTC"
                                      ) + 2
                                    )
                                  )}
                                </span>
                              )}
                            </TableCell>
                            <TableCell align="left"

                              style={{
                                fontWeight: "bold"
                                , color: Utils.isNullOrUndefined(
                                  item?.userDetails?.kycStatus
                                )
                                  ? "black"
                                  : item?.userDetails?.kycStatus.toLocaleLowerCase() === 'PENDING'.toLocaleLowerCase() ? '#b4b400'
                                    : item?.userDetails?.kycStatus.toLocaleLowerCase() === 'ESIGNED'.toLocaleLowerCase() ? 'blue'
                                      : item?.userDetails?.kycStatus.toLocaleLowerCase() === 'REJECTED'.toLocaleLowerCase() ? 'red'
                                        : item?.userDetails?.kycStatus.toLocaleLowerCase() === 'APPROVED'.toLocaleLowerCase() ? 'green' : 'black'
                              }}

                            >
                              {Utils.isNullOrUndefined(
                                item?.userDetails?.kycStatus
                              )
                                ? "-"
                                : item?.userDetails?.kycStatus}
                            </TableCell>
                            <TableCell align="center">
                              <div
                                aria-controls={item}
                                onClick={(e) => {
                                  handleOpen(item, e);
                                }}
                                className="pkt-cursor-pointer pkt-text-undeline"
                              >
                                Open Profile
                              </div>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </div >
  );
}

export default Dashboard;

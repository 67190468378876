import { Dialog, DialogContentText, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import create from "zustand";
const useConfirmationDialogStore = create((set) => ({
  message: "",
  onSubmit: undefined,
  close: () =>
    set({
      onSubmit: undefined,
    }),
}));

const ConfirmationDialog = () => {
  const { message, onSubmit, close } = useConfirmationDialogStore();
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "30%",
          height: "20%",
          maxWidth: "100%",
        },
      }}
      open={Boolean(onSubmit)}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          close();
        }
      }}
    >
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            close();
          }}
        >
          No
        </Button>{" "}
        <Button
          className="pkt-margin-left-10"
          variant="contained"
          onClick={() => {
            onSubmit();
            close();
          }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const confirmation_dialog = (message, onSubmit) => {
  useConfirmationDialogStore.setState({ message, onSubmit });
};
export { ConfirmationDialog };

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as API from "../CommonServices/Services";
import * as Utils from "../CommonServices/Utils";
import { addProfile_dialog } from "../Components/Dialogs/AddProfileialog";
import { confirmation_dialog } from "../Components/Dialogs/ConfirmationDialog";
import { status_dialog } from "../Components/Dialogs/StatusDialog";
import Header from "../Components/Header";
import Loader from "../Components/Loader";
export default function Members() {
  const [data, setData] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  let userName = localStorage.getItem("userName");
  let userType = localStorage.getItem("userType");

  const addProfile = () => {
    addProfile_dialog(null, null, () => {
      fetchData();
    });
  };
  const fetchData = () => {
    setLoading(true);

    let payload = {
      initiatorUserName: "SUPERADMIN",
      searchingForUserName: userName,
    };
    API.PostMethod("/rbac/fetchAdminDetails", payload).then((response) => {
      if (Utils.isNullOrUndefined(response?.data?.childUserNames)) {
        setData();
        setLoading(false);
      } else {
        let newArray = [];
        let totalLength = response?.data?.childUserNames?.length;
        for (let i = 0; i < totalLength; i++) {
          let payload = {
            initiatorUserName: "SUPERADMIN",
            searchingForUserName: response?.data?.childUserNames?.[i],
          };
          if (response?.data?.childUserNames?.[i].slice(-2) === "_2") {
            API.PostMethod("/rbac/fetchAdminDetails", payload).then(
              (response) => {
                let userData = response?.data;
                userData.id = i + 1;
                newArray.push(userData);
                setData(newArray);
                if (i === totalLength - 1) {
                  setLoading(false);
                }
              }
            );
          } else {
            API.PostMethod("/rbac/fetchAgentDetails", payload).then(
              (response) => {
                let userData = response?.data;
                userData.id = i + 1;
                newArray.push(userData);
                setData(newArray);
                if (i === totalLength - 1) {
                  setLoading(false);
                }
              }
            );
          }
        }
      }
    });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const editProfile = (key, event) => {
    let payload = {
      initiatorUserName: "SUPERADMIN",
      searchingForUserName: key,
    };
    if (key.slice(-2) === "_2") {
      API.PostMethod("/rbac/fetchAdminDetails", payload).then((response) => {
        let roleIds = response?.data?.roleIds[0];
        addProfile_dialog(response?.data, roleIds, () => {
          fetchData();
        });
      });
    } else {
      API.PostMethod("/rbac/fetchAgentDetails", payload).then((response) => {
        let roleIds = response?.data?.roleIds[0];
        addProfile_dialog(response?.data, roleIds, () => {
          fetchData();
        });
      });
    }
  };

  const deleteProfile = (key, event) => {
    let payload = {
      deleterAdmin: "SUPERADMIN",
      userName: key,
    };
    confirmation_dialog("Are you sure you want to delete user?", () => {
      if (key.slice(-2) === "_2") {
        API.PostMethod("/rbac/deleteAdmin", payload).then((response) => {
          let status_details = {
            ...Utils.STATUS_POPUP_DETAILS.success,
            message: "User has been deleted!",
            title: "Success",
          };
          status_dialog(status_details, null, () => {
            fetchData();
          });
        });
      } else {
        API.PostMethod("/rbac/deleteAgent", payload).then((response) => {
          let status_details = {
            ...Utils.STATUS_POPUP_DETAILS.success,
            message: "User has been deleted!",
            title: "Success",
          };
          status_dialog(status_details, null, () => {
            fetchData();
          });
        });
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="d-flex flex-column pkt-height-percentage-100 pkt-height-percentage-100 pkt-overflow-hidden ">
      <Header />
      <div className="d-flex flex-column pkt-width-percentage-100 pkt-main-height pkt-padding-20">
        <div className="d-flex flex-row justify-content-between align-items-centers">
          <div style={{ fontWeight: 600, fontSize: "20px" }}>Members</div>
          <Button
            variant="contained"
            onClick={() => {
              addProfile();
            }}
            disabled={localStorage?.userType === "AGENT"}
          >
            Add profile
          </Button>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center pkt-width-percentage-100 pkt-height-percentage-100">
          {loading ? (
            <div className="pkt-height-percentage-100 pkt-width-percentage-100">
              {" "}
              <Loader />
            </div>
          ) : !Utils.isNullOrUndefined(data) ? (
            <div
              style={{ width: "100%", overflow: "hidden" }}
              className="d-flex flex-column pkt-height-percentage-100 justify-content-between"
            >
              <TableContainer sx={{ height: "93% !important", width: "100%" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Sr. No.</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Type</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data
                      .sort((a, b) => {
                        return a.id - b.id;
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, key) => (
                        <React.Fragment key={item?.userName}>
                          <TableRow>
                            <TableCell align="left">{item?.id}</TableCell>
                            <TableCell align="left">
                              {item?.userName.slice(0, -2)}
                            </TableCell>
                            <TableCell align="left">
                              {item?.userName.slice(-2) === "_2"
                                ? "Admin"
                                : "Agent"}
                            </TableCell>
                            <TableCell align="left">
                              {item?.statusCode === 1
                                ? "Active"
                                : item?.statusCode === 2
                                  ? "Inactive"
                                  : item?.statusCode === 3
                                    ? "Suspended"
                                    : item?.statusCode === 4
                                      ? "Terminated"
                                      : "-"}
                            </TableCell>
                            <TableCell align="center">
                              <div className="d-flex flex-row justify-content-center pkt-width-percentage-100">
                                <div
                                  aria-controls={item?.userName}
                                  onClick={(e) => {
                                    editProfile(item?.userName, e);
                                  }}
                                  className="pkt-cursor-pointer pkt-text-undeline"
                                >
                                  Edit Profile
                                </div>
                                <div
                                  aria-controls={item?.userName}
                                  onClick={(e) => {
                                    deleteProfile(item?.userName, e);
                                  }}
                                  className="pkt-cursor-pointer pkt-text-undeline pkt-margin-left-15"
                                >
                                  Delete Profile
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <div>No Members found!</div>
          )}
        </div>
      </div>
    </div>
  );
}
